import React from 'react';
import { FieldInput } from 'state-template';
import schema from '../schema';

export const AdditionalJustificationCard = () => {
  const { securityChangeJustification } = schema;

  return (
    <>
      <div className={'requestFormCard'}>
        <h3>Additional Justification</h3>
        <div className={'row'}>
          <div className={'col-md-12'}>
            <>
              <FieldInput
                {...securityChangeJustification}
                disabled
                maxLength={500}
              />
              <div style={{ marginTop: '-15px', fontSize: '14px' }}>Must be 500 characters or less</div>
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdditionalJustificationCard;
