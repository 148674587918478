import React, { useEffect } from 'react';
import { Page } from 'state-template';
import { headerPadding } from '../../utils/environment';


const NotFoundPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={headerPadding()}>
      <Page>
        <div className={'centered'}>
          <div>
            <img alt={'Completed'} style={{ height: '100px', width: '100px', marginTop: '20px' }} src={'imgs/768px-Frowny.svg.png'} />
          </div>
          <div style={{ fontSize: '60px' }}>Oops</div>
        Sorry, the page you requested cannot be found
        </div>
      </Page>

    </div>
  );
};


export default NotFoundPage;
