import { handleActions } from 'redux-actions';

import * as C from './constants';

export const initialState = {
  approvedRequests: {},
  areaTypes: [],
  auth: {},
  badgeTypes: [],
  employeeManager: null,
  employees: null,
  guardRequests: { status: 'loading' },
  guardSearchParameters: null,
  hourTypes: [],
  managementRequests: { status: 'loading' },
  managementSearchParameters: null,
  managers: null,
  myApprovals: { status: 'loading' },
  myApprovalSearchParameters: null,
  myRequests: { status: 'loading' },
  myRequestsSearchParameters: null,
  pendingManagementApprovals: {},
  pendingMyApprovals: {},
  pendingSecurityApprovals: {},
  postRequest: null,
  putApprove: null,
  putCancel: null,
  putEscalate: null,
  reasonTypes: [],
  securityRequests: { status: 'loading' },
  securitySearchParameters: null,
};

const getAuthSuccess = (state, action) => ({
  ...state,
  auth: action.payload,
});

const getEmployeesSuccess = (state, action) => ({
  ...state,
  employees: action.payload,
});

const getManagersSuccess = (state, action) => ({
  ...state,
  managers: action.payload,
});

const getBadgeTypesSuccess = (state, action) => ({
  ...state,
  badgeTypes: action.payload,
});

const getHourTypesSuccess = (state, action) => ({
  ...state,
  hourTypes: action.payload,
});

const getAreaTypesSuccess = (state, action) => ({
  ...state,
  areaTypes: action.payload,
});

const getReasonTypesSuccess = (state, action) => ({
  ...state,
  reasonTypes: action.payload,
});

const getMyRequestsSuccess = (state, action) => ({
  ...state,
  myRequests: action.payload,
});

const getMyApprovalsSuccess = (state, action) => ({
  ...state,
  myApprovals: action.payload,
});

const postRequestSuccess = (state, action) => ({
  ...state,
  postRequest: action.payload,
});

const putApproveSuccess = (state, action) => ({
  ...state,
  putApprove: action.payload,
});

const putCancelSuccess = (state, action) => ({
  ...state,
  putCancel: action.payload,
});

const putEscalateSuccess = (state, action) => ({
  ...state,
  putEscalate: action.payload,
});

const getPendingSecurityApprovalsSuccess = (state, action) => ({
  ...state,
  pendingSecurityApprovals: action.payload,
});

const getPendingManagementApprovalsSuccess = (state, action) => ({
  ...state,
  pendingManagementApprovals: action.payload,
});

const getPendingMyApprovalsSuccess = (state, action) => ({
  ...state,
  pendingMyApprovals: action.payload,
});

const getApprovedRequestsSuccess = (state, action) => ({
  ...state,
  approvedRequests: action.payload,
});

const getEmployeeManagerSuccess = (state, action) => ({
  ...state,
  employeeManager: action.payload,
});

const getSecurityRequestsSuccess = (state, action) => ({
  ...state,
  securityRequests: action.payload,
});

const getGuardRequestsSuccess = (state, action) => ({
  ...state,
  guardRequests: action.payload,
});

const getManagementRequestsSuccess = (state, action) => ({
  ...state,
  managementRequests: action.payload,
});

const getSecuritySearchParametersSuccess = (state, action) => ({
  ...state,
  securitySearchParameters: action.payload,
});

const getMyRequestsSearchParametersSuccess = (state, action) => ({
  ...state,
  myRequestsSearchParameters: action.payload,
});

const getMyApprovalSearchParametersSuccess = (state, action) => ({
  ...state,
  myApprovalSearchParameters: action.payload,
});

const getGuardSearchParametersSuccess = (state, action) => ({
  ...state,
  guardSearchParameters: action.payload,
});

const getManagementSearchParametersSuccess = (state, action) => ({
  ...state,
  managementSearchParameters: action.payload,
});

export default handleActions({
  [C.GET_AUTH_SUCCESS]: getAuthSuccess,
  [C.GET_EMPLOYEES_SUCCESS]: getEmployeesSuccess,
  [C.GET_MANAGERS_SUCCESS]: getManagersSuccess,
  [C.GET_BADGE_TYPES_SUCCESS]: getBadgeTypesSuccess,
  [C.GET_HOUR_TYPES_SUCCESS]: getHourTypesSuccess,
  [C.GET_AREA_TYPES_SUCCESS]: getAreaTypesSuccess,
  [C.GET_REASON_TYPES_SUCCESS]: getReasonTypesSuccess,
  [C.GET_MY_REQUESTS_SUCCESS]: getMyRequestsSuccess,
  [C.GET_MY_APPROVALS_SUCCESS]: getMyApprovalsSuccess,
  [C.POST_REQUEST_SUCCESS]: postRequestSuccess,
  [C.PUT_APPROVE_SUCCESS]: putApproveSuccess,
  [C.PUT_CANCEL_SUCCESS]: putCancelSuccess,
  [C.PUT_ESCALATE_SUCCESS]: putEscalateSuccess,
  [C.GET_MY_APPROVALS_SUCCESS]: getMyApprovalsSuccess,
  [C.GET_PENDING_SECURITY_APPROVALS_SUCCESS]: getPendingSecurityApprovalsSuccess,
  [C.GET_PENDING_MANAGEMENT_APPROVALS_SUCCESS]: getPendingManagementApprovalsSuccess,
  [C.GET_PENDING_MY_APPROVALS_SUCCESS]: getPendingMyApprovalsSuccess,
  [C.GET_APPROVED_REQUESTS_SUCCESS]: getApprovedRequestsSuccess,
  [C.GET_EMPLOYEE_MANAGER_SUCCESS]: getEmployeeManagerSuccess,
  [C.GET_SECURITY_SEARCH_PARAMETERS_SUCCESS]: getSecuritySearchParametersSuccess,
  [C.GET_MY_REQUESTS_SEARCH_PARAMETERS_SUCCESS]: getMyRequestsSearchParametersSuccess,
  [C.GET_MY_APPROVAL_SEARCH_PARAMETERS_SUCCESS]: getMyApprovalSearchParametersSuccess,
  [C.GET_GUARD_SEARCH_PARAMETERS_SUCCESS]: getGuardSearchParametersSuccess,
  [C.GET_MANAGEMENT_SEARCH_PARAMETERS_SUCCESS]: getManagementSearchParametersSuccess,
  [C.GET_SECURITY_REQUESTS_SUCCESS]: getSecurityRequestsSuccess,
  [C.GET_GUARD_REQUESTS_SUCCESS]: getGuardRequestsSuccess,
  [C.GET_MANAGEMENT_REQUESTS_SUCCESS]: getManagementRequestsSuccess,
}, initialState);
