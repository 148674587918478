import { createAction } from 'redux-actions';

import * as C from './constants';

export const getExampleData = createAction(C.GET_EXAMPLE_DATA);
export const getExampleDataSuccess = createAction(C.GET_EXAMPLE_DATA_SUCCESS);

export const getAuth = createAction(C.GET_AUTH);
export const getAuthSuccess = createAction(C.GET_AUTH_SUCCESS);

export const loginUser = createAction(C.LOGIN_USER);
export const logoutUser = createAction(C.LOGOUT_USER);

export const getUserProfile = createAction(C.GET_USER_PROFILE);
export const getUserProfileSuccess = createAction(C.GET_USER_PROFILE_SUCCESS);

export const initApp = createAction(C.INIT_APP);

export const setAccessToken = createAction(C.SET_ACCESS_TOKEN);

export const getUserProfilePicture = createAction(C.GET_USER_PROFILE_PICTURE);
export const getUserProfilePictureSuccess = createAction(C.GET_USER_PROFILE_PICTURE_SUCCESS);

export const getEmployees = createAction(C.GET_EMPLOYEES);
export const getEmployeesSuccess = createAction(C.GET_EMPLOYEES_SUCCESS);

export const getManagers = createAction(C.GET_MANAGERS);
export const getManagersSuccess = createAction(C.GET_MANAGERS_SUCCESS);

export const getBadgeTypes = createAction(C.GET_BADGE_TYPES);
export const getBadgeTypesSuccess = createAction(C.GET_BADGE_TYPES_SUCCESS);

export const getHourTypes = createAction(C.GET_HOUR_TYPES);
export const getHourTypesSuccess = createAction(C.GET_HOUR_TYPES_SUCCESS);

export const getAreaTypes = createAction(C.GET_AREA_TYPES);
export const getAreaTypesSuccess = createAction(C.GET_AREA_TYPES_SUCCESS);

export const getReasonTypes = createAction(C.GET_REASON_TYPES);
export const getReasonTypesSuccess = createAction(C.GET_REASON_TYPES_SUCCESS);

export const getRequests = createAction(C.GET_REQUESTS);

export const clearRequests = createAction(C.CLEAR_REQUESTS);

export const getMyRequests = createAction(C.GET_MY_REQUESTS);
export const getMyRequestsSuccess = createAction(C.GET_MY_REQUESTS_SUCCESS);

export const getMyApprovals = createAction(C.GET_MY_APPROVALS);
export const getMyApprovalsSuccess = createAction(C.GET_MY_APPROVALS_SUCCESS);

export const postRequest = createAction(C.POST_REQUEST);
export const postRequestSuccess = createAction(C.POST_REQUEST_SUCCESS);

export const putApprove = createAction(C.PUT_APPROVE);
export const putApproveSuccess = createAction(C.PUT_APPROVE_SUCCESS);

export const putCancel = createAction(C.PUT_CANCEL);
export const putCancelSuccess = createAction(C.PUT_CANCEL_SUCCESS);

export const putEscalate = createAction(C.PUT_ESCALATE);
export const putEscalateSuccess = createAction(C.PUT_ESCALATE_SUCCESS);

export const getPendingSecurityApprovals = createAction(C.GET_PENDING_SECURITY_APPROVALS);
export const getPendingSecurityApprovalsSuccess = createAction(C.GET_PENDING_SECURITY_APPROVALS_SUCCESS);

export const getPendingManagementApprovals = createAction(C.GET_PENDING_MANAGEMENT_APPROVALS);
export const getPendingManagementApprovalsSuccess = createAction(C.GET_PENDING_MANAGEMENT_APPROVALS_SUCCESS);

export const getPendingMyApprovals = createAction(C.GET_PENDING_MY_APPROVALS);
export const getPendingMyApprovalsSuccess = createAction(C.GET_PENDING_MY_APPROVALS_SUCCESS);

export const getApprovedRequests = createAction(C.GET_APPROVED_REQUESTS);
export const getApprovedRequestsSuccess = createAction(C.GET_APPROVED_REQUESTS_SUCCESS);

export const getEmployeeManager = createAction(C.GET_EMPLOYEE_MANAGER);
export const getEmployeeManagerSuccess = createAction(C.GET_EMPLOYEE_MANAGER_SUCCESS);

export const getSecurityRequestsSuccess = createAction(C.GET_SECURITY_REQUESTS_SUCCESS);
export const getSecuritySearchParametersSuccess = createAction(C.GET_SECURITY_SEARCH_PARAMETERS_SUCCESS);

export const getGuardRequestsSuccess = createAction(C.GET_GUARD_REQUESTS_SUCCESS);
export const getGuardSearchParametersSuccess = createAction(C.GET_GUARD_SEARCH_PARAMETERS_SUCCESS);

export const getManagementRequestsSuccess = createAction(C.GET_MANAGEMENT_REQUESTS_SUCCESS);
export const getManagementSearchParametersSuccess = createAction(C.GET_MANAGEMENT_SEARCH_PARAMETERS_SUCCESS);

export const getMyRequestsSearchParametersSuccess = createAction(C.GET_MY_REQUESTS_SEARCH_PARAMETERS_SUCCESS);

export const getMyApprovalSearchParametersSuccess = createAction(C.GET_MY_APPROVAL_SEARCH_PARAMETERS_SUCCESS);

export const getSearchParameters = createAction(C.GET_SEARCH_PARAMETERS);
export const getSearchParametersSuccess = createAction(C.GET_SEARCH_PARAMETERS_SUCCESS);

export const getRefreshPendingCounts = createAction(C.GET_REFRESH_PENDING_COUNTS);
