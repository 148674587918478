import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'redux/actions';
import { azureAuth2 } from 'state-template';
// import { useMsalInstance } from '../../utils/aureAuth2';

export const RefreshPendingCounts = (props) => {
  const {
    getRefreshPendingCounts,
  } = props;


  const msalInstance = azureAuth2.useMsalInstance();

  useEffect(() => {
    getRefreshPendingCounts({ msalInstance });
  });

  return (
    null
  );
};

export const mapDispatchToProps = (dispatch) => ({
  getRefreshPendingCounts: (state) => dispatch(actions.getRefreshPendingCounts(state)),
});

const usingRouter = withRouter(RefreshPendingCounts);
const withRedux = connect(null, mapDispatchToProps)(usingRouter);
export default withRedux;
