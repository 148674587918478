import React, { useState, useEffect } from 'react';
import { Page, azureAuth2, Form as ReduxForm } from 'state-template';
import T from 'prop-types';
import { withRouter, useLocation, Prompt } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getFormValues, getFormInitialValues } from 'redux-form';
import _ from 'lodash';
import { MuiThemeProvider } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import * as selectors from 'redux/selectors';
import * as actions from 'redux/actions';
import theme from '../../style/components/mui';
import BadgeDetailsCard from './components/BadgeDetailsCard';
import SubmitterDetailsCard from './components/SubmitterDetailsCard';
import ApproverDetailsCard from './components/ApproverDetailsCard';
import ApprovalsDetailsCard from './components/ApprovalsDetailsCard';
import RecipientDetailsCard from './components/RecipientDetailsCard';
import AccessRequirementsCard from './components/AccessRequirementsCard';
import CancelRejectDetailsCard from './components/CancelRejectDetailsCard';
import AdditionalJustificationCard from './components/AdditionalJustificationCard';
import DownloadPDFButton from './components/DownloadPDFButton';
import ButtonCard from './components/ButtonsCard';
import displaySchema from './displaySchema.json';
// import { calculateRole } from '../../utils/CalculateRole';
import ErrorDialog from '../../components/ErrorDialog';
// import { useMsalInstance } from '../../utils/aureAuth2';
import { headerPadding } from '../../utils/environment';
import SuccessDialog from '../../components/SuccessDialog';
import ProcessingDialog from '../../components/ProcessingDialog';

export const RequestFormPage = (props) => {
  const {
    history,
    formValues,
    formInitialValues,
    cancelSuccess,
    resetPutCancelSuccess,
    approveSuccess,
    resetPutApproveSuccess,
    escalateSuccess,
    resetPutEscalateSuccess,
    getPendingSecurityApprovals,
    getPendingManagementApprovals,
    getEmployeeManager,
    auth,
  } = props;

  const location = useLocation();
  const { REQUEST_STATE } = window.config;
  let displayCard;
  const { role } = location.row;
  const { stepperTheme } = theme;
  let activeStep = 0;
  let steps = [];
  const msalInstance = azureAuth2.useMsalInstance();

  const [callAPI] = useState();
  const [isEditing, setIsEditing] = useState(true);
  const [isProcessingRequest, setIsProcessingRequest] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);

    const { id } = location.row.approver;

    if (msalInstance.accounts.length > 0) {
      getEmployeeManager({ msalInstance, id });
    }
    // eslint-disable-next-line
  }, [getEmployeeManager]);


  useEffect(() => {
    setIsProcessingRequest(false);
  }, [approveSuccess, cancelSuccess, escalateSuccess]);

  const handleReset = () => {
    setIsEditing(true);
    resetPutCancelSuccess();
    resetPutApproveSuccess();
    resetPutEscalateSuccess();
    getPendingSecurityApprovals({ msalInstance });
    getPendingManagementApprovals({ msalInstance });
    history.push(location.pageLocation);
  };

  const resetRequests = () => {
    resetPutCancelSuccess();
    resetPutApproveSuccess();
    resetPutEscalateSuccess();
  };

  const displayApproveResult = () => {
    if (approveSuccess === 'SUCCESS') {
      const success = {};
      success.title = 'Success';
      success.status = 'Successfully Approved';
      success.statusText = 'The request has been successfully approved';
      success.icon = 'check-fill';
      success.iconColor = 'green';
      return (
        <SuccessDialog
          success={success}
          handleReset={handleReset}
        />
      );
    }

    if (approveSuccess.status) {
      const err = [];
      if (approveSuccess.errorMessage && approveSuccess.errorMessage.errors) {
        const keys = Object.keys(approveSuccess.errorMessage.errors);

        keys.forEach((key) => {
          err.push(`${approveSuccess.errorMessage.errors[key]}`);
        });
      }

      const error = {};
      error.title = 'Unable to Approve Request';
      error.status = 'Unable to approve request';
      error.statusText = err;
      error.icon = 'warning-triangle';
      error.iconColor = '#b8291f';

      return (
        <ErrorDialog
          error={error}
          resetData={resetRequests}
        />
      );
    }

    return null;
  };

  const displayEscalateResult = () => {
    if (escalateSuccess === 'SUCCESS') {
      const success = {};
      success.title = 'Success';
      success.status = 'Successfully Escalated';
      success.statusText = 'The request has been successfully escalated';
      success.icon = 'check-fill';
      success.iconColor = 'green';
      return (
        <SuccessDialog
          success={success}
          handleReset={handleReset}
        />
      );
    }

    if (escalateSuccess.status) {
      const err = [];
      if (escalateSuccess.errorMessage && escalateSuccess.errorMessage.errors) {
        const keys = Object.keys(escalateSuccess.errorMessage.errors);

        keys.forEach((key) => {
          err.push(`${escalateSuccess.errorMessage.errors[key]}`);
        });
      }

      const error = {};
      error.title = 'Unable to Escalate Request';
      error.status = 'Unable to Escalate request';
      error.statusText = err;
      error.icon = 'warning-triangle';
      error.iconColor = '#b8291f';

      return (
        <ErrorDialog
          error={error}
          resetData={resetRequests}
        />
      );
    }

    return null;
  };

  const displayCancelResult = () => {
    if (cancelSuccess === 'SUCCESS') {
      const success = {};
      success.title = 'Success';
      success.status = 'Successfull Cancelled';
      success.statusText = 'The request has been successfully cancelled';
      success.icon = 'check-fill';
      success.iconColor = 'green';
      return (
        <SuccessDialog
          success={success}
          handleReset={handleReset}
        />
      );
    }

    if (cancelSuccess.status) {
      const err = [];
      if (cancelSuccess.errorMessage && cancelSuccess.errorMessage.errors) {
        const keys = Object.keys(cancelSuccess.errorMessage.errors);

        keys.forEach((key) => {
          err.push(`${cancelSuccess.errorMessage.errors[key]}`);
        });
      }

      const error = {};
      error.title = 'Unable to Cancel Request';
      error.status = 'Unable to cancel request';
      error.statusText = err;
      error.icon = 'warning-triangle';
      error.iconColor = '#b8291f';

      return (
        <ErrorDialog
          error={error}
          resetData={resetRequests}
        />
      );
    }

    return null;
  };

  const displayResult = () => {
    if (approveSuccess) {
      return displayApproveResult();
    } if (cancelSuccess) {
      return displayCancelResult();
    } if (escalateSuccess) {
      return displayEscalateResult();
    }

    return (
      null
    );
  };

  if (location.row.isEscalated) {
    steps = ['Recipient', 'Manager', 'Escalated', 'Security', 'Closed'];
  } else {
    steps = ['Recipient', 'Manager', 'Security', 'Closed'];
  }


  if (location.row) {
    displayCard = displaySchema.type.find((x) => x.id === location.row.statusType.id);
    if (location.row.isEscalated) {
      if (location.row.statusType.id === REQUEST_STATE.PENDING_RECIPIENT_APPROVAL) {
        activeStep = 0;
      } else if (location.row.statusType.id === REQUEST_STATE.PENDING_MANAGER_APPROVAL) {
        activeStep = 1;
      } else if (location.row.statusType.id === REQUEST_STATE.PENDING_SUPERVISOR_APPROVAL) {
        activeStep = 2;
      } else if (location.row.statusType.id === REQUEST_STATE.PENDING_SECURITY_APPROVAL) {
        activeStep = 3;
      } else {
        activeStep = 5;
      }
    } else if (location.row.statusType.id === REQUEST_STATE.PENDING_RECIPIENT_APPROVAL) {
      activeStep = 0;
    } else if (location.row.statusType.id === REQUEST_STATE.PENDING_MANAGER_APPROVAL) {
      activeStep = 1;
    } else if (location.row.statusType.id === REQUEST_STATE.PENDING_SECURITY_APPROVAL) {
      activeStep = 2;
    } else {
      activeStep = 4;
    }
  }

  let initialValues = {};

  if (location.row) {
    initialValues = {
      id: location.row.id,

      badgeType: location.row.badge.description,

      effectiveDate: location.row.effectiveDate ? location.row.effectiveDate.split('T')[0] : null,
      createdDate: location.row.createdDate ? location.row.createdDate.split('T')[0] : null,
      expirationDate: location.row.expirationDate ? location.row.expirationDate.split('T')[0] : null,

      submitterName: location.row.submitter ? location.row.submitter.name : null,
      submitterEmail: location.row.submitter ? location.row.submitter.email : null,

      approverName: location.row.approver ? location.row.approver.name : null,
      approverEmail: location.row.approver ? location.row.approver.email : null,
      approverPhone: location.row.approver ? location.row.approver.phone : null,
      approverId: location.row.approver ? location.row.approver.id : null,

      recipientName: location.row.recipient ? location.row.recipient.name : null,
      recipientEmail: location.row.recipient ? location.row.recipient.email : null,
      recipientPhone: location.row.recipient ? location.row.recipient.phone : null,
      recipientCell: location.row.recipient ? location.row.recipient.cell : null,
      recipientLicensePlate: location.row.recipient ? location.row.recipient.licensePlate : null,
      recipientCompany: location.row.recipient ? location.row.recipient.company : null,
      recipientCampus: location.row.recipient ? location.row.recipient.campus : null,
      recipientDivision: location.row.recipient ? location.row.recipient.division : null,
      recipientUnitProject: location.row.recipient ? location.row.recipient.unitProject : null,

      reasonRadioButton: location.row.access.reason.id,
      [`reasons_${location.row.access.reason.id}`]: location.row.access.reason.justification,
      hoursRadioButton: location.row.access.hours.id,
      [`hours_${location.row.access.hours.id}`]: location.row.access.hours.justification,
      areasCheckboxes: location.row.access.areas.map((x) => x.value),

      approvalName: location.row.approval ? location.row.approval.name : null,
      approvalDate: location.row.approval ? location.row.approval.date ? location.row.approval.date.split('T')[0] : null : null,

      cancelRejectName: location.row.cancelReject ? location.row.cancelReject.name : null,
      cancelRejectDate: location.row.cancelReject ? location.row.cancelReject.date ? location.row.cancelReject.date.split('T')[0] : null : null,
      cancelRejectJustification: location.row.cancelReject ? location.row.cancelReject.justification : null,

      securityChangeJustification: location.row.securityChangeJustification,
    };

    location.row.access.areas.forEach((area) => {
      if (area.justification) {
        initialValues[`areas_${area.value}`] = area.justification;
      }
    });

    if (location.row.approvals) {
      location.row.approvals.forEach((approval) => {
        initialValues[`approval_${approval.type}`] = approval.type;
        initialValues[`approval_${approval.type}_name`] = approval.name;
        initialValues[`approval_${approval.type}_date`] = approval.date ? approval.date.split('T')[0] : null;
      });
    }
  }

  if (!location.row) {
    return (
      <>
        <div className={headerPadding()}>
          <Page title={'Request'}>
            <div style={{ textAlign: 'center', fontSize: '20px' }}>
              <div style={{ marginTop: '10px' }}>
                <img alt={'Completed'} style={{ height: '100px', width: '100px' }} src={'imgs/clipart1539874.png'} />
              </div>
              The request cannot be found or has already been processed.
            </div>
          </Page>
        </div>
      </>
    );
  }


  const callingApi = () => {
    setIsEditing(false);
    setIsProcessingRequest(true);
  };

  const displayProcessing = () => {
    if (isProcessingRequest) {
      return (
        <ProcessingDialog />
      );
    }

    return null;
  };

  const displayError = () => {
    if (callAPI) {
      return (
        <ErrorDialog
          error={callAPI}
        />
      );
    }

    return (
      null
    );
  };

  const displayStepper = () => {
    if (location.row.statusType.id < REQUEST_STATE.APPROVED) {
      return (
        <div style={{ textAlign: 'center', paddingTop: '10px' }}>
          <div style={{ wordBreak: 'break-word' }}>
            <MuiThemeProvider theme={stepperTheme}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </MuiThemeProvider>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={headerPadding()}>
      <Page title={'Request'}>
        <ReduxForm
          form={'request'}
          onSubmit
          initialValues={initialValues}
        >

          <p style={{ border: '1px', borderStyle: 'solid', padding: '5px', backgroundColor: '#e5f0f4' }}>
            Requests may be escalated or rejected depending on the
            state of the request.  A request may be escalated if the manager has not yet approved/rejected the request. A request
            may be rejected as long as the request is still pending approval by a manager, supervisor,
            or security.  If you submit a request where you are both the submitter and recipient, then the request shall be moved to
            &quot;Pending Manager Approval&quot;.  Otherwise, the request shall be moved to &quot;Pending Recipient Approval&quot;.  A request cannot be reopened if security approves the request or security, manager, supervisor,
            submitter, or recipient rejects the request.
          </p>
          <br />
          <>
            <Prompt
              when={!_.isEqual(formValues, formInitialValues) && isEditing}
              message={'You are currently editing the request. Your changes will be lost if you leave.'}
            />
            {/* {testingCard()} */}
            {displayResult()}
            <div style={{ textAlign: 'center' }}>
              <div style={{ minWidth: '100px', fontSize: '20px', display: 'inline-block', padding: '10px', width: 'auto', height: 'auto', backgroundColor: '#046b99', borderRadius: '8px', border: '1px', borderStyle: 'solid', borderColor: 'black' }}>
                <div style={{ fontSize: '40px', color: '#ffffff' }}>{location.row.recipient.name}</div>
                <div style={{ fontSize: '20px', display: 'inline-block', padding: '5px', width: 'auto', height: 'auto', backgroundColor: '#ffffff', borderRadius: '8px' }}>
                  <div>
                    <strong>Request:</strong>
                    {' '}
                    {location.row.id}
                  </div>
                  <div>
                    <strong>My Role:</strong>
                    {' '}
                    {location.row.role}
                  </div>
                  <div>
                    <strong>Status:</strong>
                    {' '}
                    {location.row.statusType.description}
                  </div>
                </div>
              </div>
            </div>

            {displayStepper()}

            <div style={{ margin: '2vh auto', width: '100%', paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px', paddingTop: '1px' }} className={'shadow'}>
              {displayCard.downloadPDF && displayCard.downloadPDF[role] && displayCard.downloadPDF[role].display
              && (
              <div style={{ textAlign: 'right' }}>
                <DownloadPDFButton
                  role={role}
                  auth={auth}
                  data={location.row}
                />
              </div>
              )}
              {displayCard.badge && displayCard.badge[role] && displayCard.badge[role].display
                && (
                <BadgeDetailsCard
                  data={location.row}
                  displayFields={displayCard}
                  role={role}
                />
                )}
              {displayCard.submitter && displayCard.submitter[role] && displayCard.submitter[role].display
                && (
                <SubmitterDetailsCard
                  displayFields={displayCard}
                  role={role}
                />
                )}
              {displayCard.approver && displayCard.approver[role] && displayCard.approver[role].display
                && (
                <ApproverDetailsCard
                  displayFields={displayCard}
                  role={role}
                />
                )}
              {displayCard.recipient && displayCard.recipient[role] && displayCard.recipient[role].display
                && (
                <RecipientDetailsCard
                  displayFields={displayCard}
                  role={role}
                />
                )}
              {displayCard.accessRequirements && displayCard.accessRequirements[role] && displayCard.accessRequirements[role].display
                && (
                <AccessRequirementsCard
                  data={location.row}
                  displayFields={displayCard}
                  role={role}
                />
                )}
              {displayCard.approvals && displayCard.approvals[role] && displayCard.approvals[role].display
                && (
                <ApprovalsDetailsCard
                  data={location.row}
                  displayFields={displayCard}
                  role={role}
                />
                )}

              {location.row.securityChangeJustification && displayCard.securityJustification && displayCard.securityJustification[role] && displayCard.securityJustification[role].display
                && (
                <AdditionalJustificationCard
                  displayFields={displayCard}
                  role={role}
                />
                )}

              {displayCard.cancelReject && displayCard.cancelReject[role] && displayCard.cancelReject[role].display
                && (
                <CancelRejectDetailsCard
                  // disabled={displayCard.cancelReject.disabled}
                  displayFields={displayCard}
                  role={role}
                />
                ) }
              {displayCard.buttons && displayCard.buttons[role]
                && (
                  <ButtonCard
                    leftButton={displayCard.buttons[role].left ? displayCard.buttons[role].left : null}
                    middleButton={displayCard.buttons[role].middle ? displayCard.buttons[role].middle : null}
                    rightButton={displayCard.buttons[role].right ? displayCard.buttons[role].right : null}
                    currentRole={role}
                    callingApi={callingApi}
                  />
                )}
            </div>
            {displayProcessing()}
            {displayError()}
          </>
        </ReduxForm>
      </Page>

    </div>
  );
};

RequestFormPage.propTypes = {
  history: T.shape({
    push: T.func,
  }).isRequired,
  formValues: T.object,
  formInitialValues: T.object,
  resetPutCancelSuccess: T.func.isRequired,
  resetPutApproveSuccess: T.func.isRequired,
  resetPutEscalateSuccess: T.func.isRequired,
  cancelSuccess: T.oneOfType([
    T.string,
    T.object,
  ]),
  approveSuccess: T.oneOfType([
    T.string,
    T.object,
  ]),
  escalateSuccess: T.oneOfType([
    T.string,
    T.object,
  ]),
  auth: T.object,
  getPendingSecurityApprovals: T.func.isRequired,
  getPendingManagementApprovals: T.func.isRequired,
  getEmployeeManager: T.func.isRequired,
};

RequestFormPage.defaultProps = {
  formValues: {},
  formInitialValues: {},
  cancelSuccess: null,
  approveSuccess: null,
  escalateSuccess: null,
  auth: {},
};

export const mapStateToProps = createStructuredSelector({
  formValues: getFormValues('request'),
  formInitialValues: getFormInitialValues('request'),
  approveSuccess: selectors.getPutApprove(),
  cancelSuccess: selectors.getPutCancel(),
  escalateSuccess: selectors.getPutEscalate(),
  auth: selectors.getAuth(),
});

export const mapDispatchToProps = (dispatch) => ({
  resetPutCancelSuccess: () => dispatch(actions.putCancelSuccess()),
  resetPutApproveSuccess: () => dispatch(actions.putApproveSuccess()),
  resetPutEscalateSuccess: () => dispatch(actions.putEscalateSuccess()),
  getPendingSecurityApprovals: (request) => dispatch(actions.getPendingSecurityApprovals(request)),
  getPendingManagementApprovals: (request) => dispatch(actions.getPendingManagementApprovals(request)),
  getEmployeeManager: (request) => dispatch(actions.getEmployeeManager(request)),
});

const usingRouter = withRouter(RequestFormPage);
const usingRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);
export default usingRedux;
