import React, { useEffect } from 'react';
import { Footer, Routing, azureAuth2 } from 'state-template';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as selectors from 'redux/selectors';
import * as actions from 'redux/actions';
import Header from 'components/Header/Header';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import { useHistory } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import { Routes } from '../../routes';
// import { useMsalInstance } from '../../utils/aureAuth2';

const App = (props) => {
  const {
    auth, initApp, getAuth,
  } = props;

  const { MSAL, API_SCOPES } = window.config;

  const isAuthenticated = useIsAuthenticated();
  const msalInstance = azureAuth2.useMsalInstance();
  const history = useHistory();
  const pages = ['/home', '/my-requests', '/my-approvals', '/management', '/request-form', '/new-request', '/help', '/', '/security', '/guard'];


  const calculateAuthLocation = () => {
    let authLocation = '';

    if (history.location.pathname === '/' || history.location.pathname === '/request-form') {
      authLocation = '/home';
    } else if (!pages.find((page) => page === history.location.pathname)) {
      authLocation = '/not-found';
    }

    return authLocation;
  };

  useEffect(() => {
    if (!isAuthenticated) {
      getAuth({});
    } else {
      const apiScopes = API_SCOPES;
      const msal = MSAL;
      initApp({ msalInstance, msal, apiScopes });
    }
    // eslint-disable-next-line
  }, [isAuthenticated, getAuth, initApp, API_SCOPES, MSAL]);


  const footerLinks = [
    {
      href: 'https://www.ca.gov/use/',
      text: 'Conditions of Use',
    },
    { href: 'https://www.ca.gov/privacy-policy/',
      text: 'Privacy Policy',
    },
    {
      href: 'https://www.ca.gov/accessibility/',
      text: 'Accesibility',
    },
    { href: 'https://cdt.ca.gov/support/',
      text: 'Contact Us' },
    {
      to: '/terms-of-service',
      text: 'Terms',
    },
  ];
  const contactLink = {
    href: 'https://cdt.ca.gov/support/',
    text: 'Contact Us',
  };

  return (
    <ErrorBoundary>
      <Header />
      <AuthenticatedTemplate>
        <Routing routes={Routes(auth)} redirect={calculateAuthLocation()} />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routing routes={Routes(auth)} redirect={'/'} />
      </UnauthenticatedTemplate>
      <Footer footerLinks={footerLinks} contactLink={contactLink} />
    </ErrorBoundary>
  );
};

App.propTypes = {
  /** App initialization */
  initApp: T.func.isRequired,
  /** user authentication information */
  auth: T.object,
  getAuth: T.func.isRequired,
};

App.defaultProps = {
  auth: {},
};

export const mapStateToProps = createStructuredSelector({
  auth: selectors.getAuth(),
});

export const mapDispatchToProps = (dispatch) => ({
  initApp: (msal) => dispatch(actions.initApp(msal)),
  getAuth: (input) => dispatch(actions.getAuth(input)),
  login: () => dispatch(actions.loginUser()),
  logout: () => dispatch(actions.logoutUser()),
});

const usingRedux = connect(mapStateToProps, mapDispatchToProps)(App);
export default usingRedux;
