import { createSelector } from 'reselect';

export const selectDomain = (state) => state.reducer;
export const selectExampleData = (state) => selectDomain(state).exampleData;
export const selectLastFetched = (state) => selectDomain(state).lastFetched;
export const selectAuth = (state) => selectDomain(state).auth;
export const selectEmployees = (state) => selectDomain(state).employees;
export const selectManagers = (state) => selectDomain(state).managers;
export const selectBadgeTypes = (state) => selectDomain(state).badgeTypes;
export const selectHourTypes = (state) => selectDomain(state).hourTypes;
export const selectAreaTypes = (state) => selectDomain(state).areaTypes;
export const selectReasonTypes = (state) => selectDomain(state).reasonTypes;
export const selectRequests = (state) => selectDomain(state).requests;
export const selectMyRequests = (state) => selectDomain(state).myRequests;
export const selectMyApprovals = (state) => selectDomain(state).myApprovals;
export const selectPostRequest = (state) => selectDomain(state).postRequest;
export const selectPutApprove = (state) => selectDomain(state).putApprove;
export const selectPutCancel = (state) => selectDomain(state).putCancel;
export const selectPutEscalate = (state) => selectDomain(state).putEscalate;
export const selectGetAuth = (state) => selectDomain(state).getAuth;
export const selectPendingSecurityApprovals = (state) => selectDomain(state).pendingSecurityApprovals;
export const selectPendingManagementApprovals = (state) => selectDomain(state).pendingManagementApprovals;
export const selectPendingMyApprovals = (state) => selectDomain(state).pendingMyApprovals;
export const selectApprovedRequests = (state) => selectDomain(state).approvedRequests;
export const selectEmployeeManager = (state) => selectDomain(state).employeeManager;
export const selectSecuritySearchParameters = (state) => selectDomain(state).securitySearchParameters;
export const selectGuardSearchParameters = (state) => selectDomain(state).guardSearchParameters;
export const selectMyRequestsSearchParameters = (state) => selectDomain(state).myRequestsSearchParameters;
export const selectMyApprovalSearchParameters = (state) => selectDomain(state).myApprovalSearchParameters;
export const selectManagementSearchParameters = (state) => selectDomain(state).managementSearchParameters;
export const selectSearchParameters = (state) => selectDomain(state).searchParameters;
export const selectSecurityRequests = (state) => selectDomain(state).securityRequests;
export const selectGuardRequests = (state) => selectDomain(state).guardRequests;
export const selectManagementRequests = (state) => selectDomain(state).managementRequests;

const { PAGES } = window.config;

export const getExampleData = () => createSelector(
  selectExampleData,
  (exampleData) => exampleData,
);

export const getLastFetched = () => createSelector(
  selectLastFetched,
  (lastFetched) => lastFetched,
);

export const getAuth = () => createSelector(
  selectAuth,
  (auth) => auth,
);

export const getEmployees = () => createSelector(
  selectEmployees,
  (employees) => employees,
);

export const getManagers = () => createSelector(
  selectManagers,
  (managers) => managers,
);

export const getBadgeTypes = () => createSelector(
  selectBadgeTypes,
  (badgeTypes) => badgeTypes,
);

export const getHourTypes = () => createSelector(
  selectHourTypes,
  (hourTypes) => hourTypes,
);

export const getAreaTypes = () => createSelector(
  selectAreaTypes,
  (areaTypes) => areaTypes,
);

export const getReasonTypes = () => createSelector(
  selectReasonTypes,
  (reasonTypes) => reasonTypes,
);

export const getRequests = (form) => {
  if (form === PAGES.SECURITY) {
    return (createSelector(
      selectSecurityRequests,
      (securityRequests) => securityRequests,
    ));
  }

  if (form === PAGES.MY_REQUESTS) {
    return (createSelector(
      selectMyRequests,
      (myRequests) => myRequests,
    ));
  }

  if (form === PAGES.MY_APPROVAL) {
    return (createSelector(
      selectMyApprovals,
      (myApprovals) => myApprovals,
    ));
  }

  if (form === PAGES.GUARD) {
    return (createSelector(
      selectGuardRequests,
      (guardRequests) => guardRequests,
    ));
  }

  if (form === PAGES.MANAGEMENT) {
    return (createSelector(
      selectManagementRequests,
      (managementRequests) => managementRequests,
    ));
  }

  return null;
};

export const getMyRequests = () => createSelector(
  selectMyRequests,
  (myRequests) => myRequests,
);

export const getMyApprovals = () => createSelector(
  selectMyApprovals,
  (myApprovals) => myApprovals,
);

export const getPostRequest = () => createSelector(
  selectPostRequest,
  (postRequest) => postRequest,
);

export const getPutApprove = () => createSelector(
  selectPutApprove,
  (putApprove) => putApprove,
);

export const getPutCancel = () => createSelector(
  selectPutCancel,
  (putCancel) => putCancel,
);

export const getPutEscalate = () => createSelector(
  selectPutEscalate,
  (putCancel) => putCancel,
);

export const getPendingSecurityApprovals = () => createSelector(
  selectPendingSecurityApprovals,
  (pendingSecurityApprovals) => pendingSecurityApprovals,
);

export const getPendingManagementApprovals = () => createSelector(
  selectPendingManagementApprovals,
  (pendingManagementApprovals) => pendingManagementApprovals,
);

export const getPendingMyApprovals = () => createSelector(
  selectPendingMyApprovals,
  (pendingMyApprovals) => pendingMyApprovals,
);

export const getApprovedRequests = () => createSelector(
  selectApprovedRequests,
  (approvedRequests) => approvedRequests,
);

export const getEmployeeManager = () => createSelector(
  selectEmployeeManager,
  (employeeManager) => employeeManager,
);

export const getSearchParameters = (form) => {
  if (form === PAGES.SECURITY) {
    return (createSelector(
      selectSecuritySearchParameters,
      (securitySearchParameters) => securitySearchParameters,
    ));
  }

  if (form === PAGES.MY_REQUESTS) {
    return (createSelector(
      selectMyRequestsSearchParameters,
      (myRequestsSearchParameters) => myRequestsSearchParameters,
    ));
  }

  if (form === PAGES.MY_APPROVAL) {
    return (createSelector(
      selectMyApprovalSearchParameters,
      (myApprovalSearchParameters) => myApprovalSearchParameters,
    ));
  }

  if (form === PAGES.GUARD) {
    return (createSelector(
      selectGuardSearchParameters,
      (guardSearchParameters) => guardSearchParameters,
    ));
  }

  if (form === PAGES.MANAGEMENT) {
    return (createSelector(
      selectManagementSearchParameters,
      (managementSearchParameters) => managementSearchParameters,
    ));
  }

  return null;
};
