import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { Icon, Button } from 'state-template';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';

export const SuccessDialog = (props) => {
  const {
    success,
    handleReset,
  } = props;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (success !== undefined) {
      setOpen(true);
    }
  }, [success]);

  const handleClose = () => {
    setOpen(false);
    handleReset();
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth={'lg'}
        style={{ zIndex: 3000 }}
        id={'dialogId'}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >

        <DialogTitle
          id={'scroll-dialog-title'}
        >
          <Icon
            name={success.icon}
            srOnly={'Warning'}
            style={{ paddingRight: '10px', color: success.iconColor }}
          />
          {success.title}
        </DialogTitle>
        <DialogContent dividers>
          Success -
          {' '}
          {`${success.statusText}`}
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <Button
              variant={'primary'}
              onClick={handleClose}
              name={'close'}
            >
                Close
            </Button>
          </div>

        </DialogContent>

      </Dialog>
    </>
  );
};

SuccessDialog.propTypes = {
  success: T.object,
  handleReset: T.func.isRequired,
};

SuccessDialog.defaultProps = {
  success: {},
};

export default SuccessDialog;
