import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

export const ProcessingDialog = () => (
  <Dialog
    open
    maxWidth={'lg'}
    style={{ zIndex: 3000 }}
    id={'dialogId'}
    onSubmit={(e) => {
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    <DialogContent dividers>
      <i
        className={'fa fa-refresh fa-spin'}
        style={{ marginRight: '5px' }}
      />
          The request is being processed
    </DialogContent>
  </Dialog>
);
export default ProcessingDialog;
