import React from 'react';
import Request from '../../components/Request';
import RefreshPendingCounts from '../../components/RefreshPendingCounts';

const { PAGES, ROLES } = window.config;

export const GuardPage = () => (
  <>
    <RefreshPendingCounts />
    <Request
      heading={'Guard'}
      description={'This list contains all the badge requests which have been approved by security.'}
      pageName={PAGES.GUARD}
      role={ROLES.BARS_GUARD}
      displaySearch={false}
      refresh={Math.random()} // Forces page to rerender by updating prop
    />
  </>
);

export default GuardPage;
