import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Page, azureAuth2 } from 'state-template';
import * as selectors from 'redux/selectors';
import * as actions from 'redux/actions';
import { envHomeHeader } from '../../utils/environment';
// import { SignInButton } from '../../utils/aureAuth2';

const banner = (
  <div
    className={'headerTopPadding header-decoration hidden-print text-center section-primary'}
    style={{
      backgroundImage: 'url(/imgs/background-primary.jpg)',
      backgroundSize: 'cover',
    }}
  >
    <div className={'text-center'}>
      <div className={'row'}>
        <div className={'half offset-quarter'}>
          <div className={'m-b-lg'}>
            <h1 className={'display m-b'}>
              <font style={{ color: '#ffffff' }}>Badge Access Request System</font>
            </h1>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className={envHomeHeader()}>
      {banner}

      <Page>
        <div className={'headerTopPaddinghome'} style={{ textAlign: 'center' }}>
          <h2>
Welcome to the Badge Access Request System
            <br />
(BARS)
          </h2>
          {' '}
        Secure access for every California Department of Technology employee, contractor, and guest for every site.
          <br />
          <div style={{ marginTop: '30px' }}>
            <azureAuth2.SignInButton />
          </div>
        </div>
      </Page>

    </div>
  );
};


HomePage.defaultProps = {

};

export const mapStateToProps = createStructuredSelector({
  auth: selectors.getAuth(),
  // exampleData: selectors.getExampleData(),
});

export const mapDispatchToProps = (dispatch) => ({
  // getExampleData: () => dispatch(actions.getExampleData()),
  // login: () => dispatch(actions.loginUser()),
  getAuth: () => dispatch(actions.getAuth()),
});

// const withRedux = connect(mapStateToProps, mapDispatchToProps)(HomePage);

// export default withRedux;
const usingRouter = withRouter(HomePage);
const usingRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);
export default usingRedux;
