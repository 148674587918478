import { createMuiTheme } from '@material-ui/core/styles';

const stepperTheme = createMuiTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        '&$completed': {
          color: '#046b99',
        },
        '&$active': {
          color: '#8a2be2',
        },
      },
      active: {},
      completed: {},
    },
  } });

const theme = {
  stepperTheme,
};

export default theme;
