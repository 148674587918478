import React from 'react';
import RefreshPendingCounts from '../../components/RefreshPendingCounts';
import HelpPageContent from './components/HelpPageContent';


const HelpPage = () => (
  <>
    <RefreshPendingCounts />
    <HelpPageContent
      refresh={Math.random()}
    />
  </>
);


export default HelpPage;
