import React from 'react';
import { withRouter, Prompt } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { FieldInput, FieldReactSelect, FieldRadioButtons, FieldCheckboxes } from 'state-template';
import { FormSection, getFormValues, change } from 'redux-form';
import { components } from 'react-select';
import { WindowedMenuList } from 'react-windowed-select';
import T from 'prop-types';
import * as selectors from '../../../redux/selectors';
import schema from '../schema';

export const indicatorSeparatorStyle = {
  alignSelf: 'stretch',
  backgroundColor: 'lightgray',
  width: 1,
};

const CaretDownIcon = () => <div className={'ca-gov-icon-triangle-down'} />;

export const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <CaretDownIcon />
  </components.DropdownIndicator>
);

// eslint-disable-next-line max-len
export const IndicatorSeparator = ({ innerProps }) => <span style={indicatorSeparatorStyle} {...innerProps} />;

export const Wizard = (props) => {
  const {
    activeStep,
    employees,
    managers,
    updateField,
    formValues,
    areas,
    setAreas,
    reasons,
    setReason,
    hours,
    setHours,
    hourTypes,
    areaTypes,
    reasonTypes,
    auth,
  } = props;

  const { recipientDetails, access, managerDetails } = schema;
  const reasonOptions = reasonTypes.map((reasonType) => ({
    value: reasonType.id,
    label: reasonType.isJustificationRequired ? `${reasonType.description} *` : reasonType.description,
    justification: reasonType.isJustificationRequired,
    tooltip: reasonType.detail,
    placeholder: `Enter justification for ${reasonType.description}`,
  }));

  const areaOptions = areaTypes.map((areaType) => ({
    value: areaType.id,
    label: areaType.isJustificationRequired ? `${areaType.description} *` : areaType.description,
    justification: areaType.isJustificationRequired,
    tooltip: areaType.detail,
    placeholder: `Enter justification for ${areaType.description}`,
  }));

  const hourOptions = hourTypes.map((hourType) => ({
    value: hourType.id,
    label: hourType.isJustificationRequired ? `${hourType.description} *` : hourType.description,
    justification: hourType.isJustificationRequired,
    tooltip: hourType.detail,
    placeholder: `Enter justification for ${hourType.description}`,
  }));

  const onChangeName = (name) => {
    const employee = employees.find((x) => x.id === name.value);
    updateField('newRequest', 'recepientDetails.email', employee.email);
    updateField('newRequest', 'recepientDetails.phone', employee.deskPhone);
    updateField('newRequest', 'recepientDetails.cell', employee.mobilePhone);
    updateField('newRequest', 'recepientDetails.campus', employee.officeLocation);
    updateField('newRequest', 'recepientDetails.division', employee.department);
    updateField('newRequest', 'recepientDetails.unitOrProject', employee.unit);
  };

  const needReasonJustification = (event) => {
    updateField('newRequest', 'reasons.justification', undefined);
    setReason(event);
  };

  const needHoursJustification = (event) => {
    updateField('newRequest', 'hours.justification', undefined);
    setHours(event);
  };

  const needAreasJustification = (event) => {
    if (areaTypes.length > 0) {
      areaTypes.forEach((value) => {
        const isFound = event.find((x) => x === value.id);
        if (!isFound) {
          updateField('newRequest', `areas.justification_${value.id}`, undefined);
        }
      });
    }

    setAreas(event);
  };

  const renderHoursJustification = () => {
    const foundHour = hourOptions.find((x) => x.value === hours);

    if (foundHour && foundHour.justification === true) {
      return (
        <>
          <FieldInput
            required
            name={'hours.justification'}
            label={foundHour.label.replace(' *', '')}
            tag={'textarea'}
            tooltip={foundHour.tooltip}
            placeholder={foundHour.placeholder}
            maxLength={500}
          />
          <div style={{ marginTop: '-15px', fontSize: '14px' }}>Must be 500 characters or less</div>
        </>
      );
    }

    return (
      null
    );
  };

  const renderReasonJustification = () => {
    const foundReason = reasonOptions.find((x) => x.value === reasons);

    if (foundReason && foundReason.justification === true) {
      return (
        <>
          <FieldInput
            required
            name={'reasons.justification'}
            label={foundReason.label.replace(' *', '')}
            tag={'textarea'}
            tooltip={foundReason.tooltip}
            placeholder={foundReason.placeholder}
            maxLength={500}
          />
          <div style={{ marginTop: '-15px', fontSize: '14px' }}>Must be 500 characters or less</div>
        </>
      );
    }

    return (
      null
    );
  };

  const renderSingleAreaJustification = (area) => (
    <div key={`areas.justification_${area.value}`}>
      <FieldInput
        required
        name={`areas.justification_${area.value}`}
        label={area.label.replace(' *', '')}
        tag={'textarea'}
        key={area.value}
        tooltip={area.tooltip}
        placeholder={area.placeholder}
        maxLength={500}
      />
      <div style={{ marginTop: '-15px', fontSize: '14px' }}>Must be 500 characters or less</div>
    </div>
  );

  const renderAreasJustificationList = () => {
    const areaList = [];
    for (let i = 0; i < areas.length; i += 1) {
      const foundArea = areaOptions.find((x) => x.value === areas[i]);
      if (foundArea.justification) {
        areaList.push(renderSingleAreaJustification(foundArea));
      }
    }

    return (
      areaList
    );
  };

  const renderAreasJustification = () => {
    if (areas) {
      return (
        <div>
          {
            renderAreasJustificationList()
          }
        </div>
      );
    }

    return (
      null
    );
  };

  const renderAreaOtherArea = () => {
    if (areas.find((x) => x === 'value8')) {
      return (
        <>
          <FieldInput
            required
            name={'otherAreaTextArea'}
            label={'Other Area'}
            tag={'textarea'}
            placeholder={'Enter other area'}
            maxLength={500}
          />
          <div style={{ marginTop: '-15px', fontSize: '14px' }}>Must be 500 characters or less</div>
        </>
      );
    }

    return (
      null
    );
  };

  const employeeList = [];
  if (employees.length > 0) {
    employees.forEach((emp) => {
      let entry = {};
      if (emp.hasPendingRequests) {
        entry = {
          value: emp.id,
          label: `${emp.firstName} ${emp.lastName} - Pending Request `,
          isDisabled: true,
        };
      } else {
        entry = {
          value: emp.id,
          label: `${emp.firstName} ${emp.lastName}`,
        };
      }
      employeeList.push(entry);
    });
  }
  const renderCurrentUserIsApprovingManager = () => {
    if (formValues && formValues.recepientDetails && formValues.recepientDetails.approvingManager) {
      if (formValues.recepientDetails.name.value !== formValues.recepientDetails.approvingManager.value) {
        if (auth.id === formValues.recepientDetails.approvingManager.value) {
          return (
            <p style={{ fontStyle: 'italic' }}>
              Note: You are selecting yourself as manager. Please note you will be asked to approve this same request after the above recipient approves.
            </p>
          );
        }
      }
    }
    return null;
  };

  const renderRecipientIsApprovingManager = () => {
    if (formValues && formValues.recepientDetails && formValues.recepientDetails.approvingManager) {
      if (formValues.recepientDetails.name.value === formValues.recepientDetails.approvingManager.value) {
        return (
          <p style={{ fontStyle: 'italic' }}>
            {/* <span class={{className:''}}>* </span>Recipient and Approving manager cannot be same. */}
            <span style={{ color: '#9F3123', fontWeight: '700' }}>* </span>
Recipient and Approving manager cannot be same.
          </p>
        );
      }
    }
    return null;
  };

  if (activeStep === 0) {
    return (
      <>
        <Prompt
          when={formValues !== undefined}
          message={'You are currently editing the New Request form. Your changes will be lost if you leave.'}
        />
        <div style={{ textAlign: 'center' }}>
          <h2>Recipient Details</h2>
        </div>
        {/* <hr /> */}
        <div style={{ margin: '2vh auto', width: '75%', paddingLeft: '10px', paddingRight: '10px', paddingTop: '1px', paddingBottom: '10px' }} className={'shadow'}>
          <p>If you do not see your recipients name in the list below, please make sure the appropriate staff have:</p>
          <ul>
            <li>
Followed the Staff Movement Guide instructions (
              <a href={'http://icentral.technology.ca.gov/employee-resources/onboarding/hiring-managers/staff-movement-guide/'} title={'If you are within CDT Network, please use this link.'}>Non-Portal</a>
,
              {' '}
              <a href={'https://portal.cdt.ca.gov/http/icentral.technology.ca.gov/employee-resources/onboarding/hiring-managers/staff-movement-guide/'} title={'If you are outside of CDT Network, please use this link. It may prompt you to login.'}>Portal</a>
) as defined within iCentral.
            </li>
            <li>Allowed upwards of 7 days after Staff Movement approvals to allow for associated processes to complete.</li>
          </ul>
          <p>The Staff Movement Process ensures users are properly tracked, traced, and securely added to all sub-systems.</p>
          <FormSection name={'recepientDetails'} className={'requestFormCard'}>
            {/* <FieldReactSelect
              {...recipientDetails.badgeType}
              options={badgeTypes}
              renderTarget={'default'}
              menuPlacement={'auto'}
            /> */}
            <FieldReactSelect
              {...recipientDetails.name}
              // options={employees.map((x) => ({ value: x.id, label: `${x.firstName} ${x.lastName}` }))}
              options={employeeList}
              components={{ MenuList: WindowedMenuList, IndicatorSeparator, DropdownIndicator }}
              onChange={(name) => onChangeName(name)}
            />
            <FieldReactSelect
              {...managerDetails.approvingManager}
              options={managers}
            />
            {renderCurrentUserIsApprovingManager()}
            {renderRecipientIsApprovingManager()}

            <FieldInput
              {...recipientDetails.email}
            />
            <FieldInput
              {...recipientDetails.phone}
            />
            <FieldInput
              {...recipientDetails.cell}
            />
            <FieldInput
              {...recipientDetails.licensePlate}
            />
            <FieldInput
              {...recipientDetails.campus}
            />
            {/* <FieldInput
              {...recipientDetails.division}
            /> */}
            <FieldInput
              {...recipientDetails.unitOrProject}
            />
            {/* <FieldDate
              minDate={new Date()}
              name={'minDate'}
              {...recipientDetails.effectiveDate}
            /> */}
          </FormSection>
        </div>
      </>
    );
  }


  if (activeStep === 1) {
    return (
      <>
        <Prompt
          when={formValues !== undefined}
          message={'You are currently editing the New Request form. Your changes will be lost if you leave.'}
        />
        <div style={{ textAlign: 'center' }}>
          <h2>Access Requirements</h2>
        </div>
        {/* <hr /> */}
        <div style={{ margin: '2vh auto', width: '100%', paddingLeft: '10px', paddingRight: '10px', paddingTop: '1px', paddingBottom: '10px' }} className={'shadow'}>
          <div className={'requestFormCard'}>
            <div className={'row'}>
              <div className={'col-md-4'}>
                <FormSection name={'reasons'}>
                  <FieldRadioButtons
                    {...access.reason}
                    options={reasonOptions}
                    onChange={needReasonJustification}
                  />
                </FormSection>
              </div>
              <div className={'col-md-4'}>
                <FormSection name={'areas'}>
                  <FieldCheckboxes
                    {...access.areas}
                    options={areaOptions}
                    onChange={needAreasJustification}
                  />
                  {renderAreaOtherArea()}
                </FormSection>
              </div>
              <div className={'col-md-4'}>
                <FormSection name={'hours'}>
                  <FieldRadioButtons
                    {...access.hours}
                    options={hourOptions}
                    onChange={needHoursJustification}
                  />
                </FormSection>
              </div>
            </div>
            <div style={{ textAlign: 'center' }}>
              Fields marked with an asterisk (*) require a justification.
            </div>
            {renderReasonJustification()}
            {renderAreasJustification()}
            {renderHoursJustification()}
          </div>
        </div>
      </>
    );
  }

  return (
    null
  );
};

Wizard.propTypes = {
  auth: T.object,
  activeStep: T.number.isRequired,
  employees: T.array,
  managers: T.array,
  updateField: T.func.isRequired,
  formValues: T.object,
  areas: T.array,
  setAreas: T.func.isRequired,
  reasons: T.number,
  setReason: T.func.isRequired,
  hours: T.number,
  setHours: T.func.isRequired,
  badgeTypes: T.arrayOf(
    T.shape({
      number: T.string,
      label: T.string,
      description: T.string,
    }),
  ),
  hourTypes: T.arrayOf(
    T.shape({
      id: T.number,
      description: T.string,
      detail: T.string,
      isJustificationRequired: T.bool,
      map: T.func,
    }),
  ),
  areaTypes: T.arrayOf(
    T.shape({
      id: T.number,
      description: T.string,
      detail: T.string,
      isJustificationRequired: T.bool,
      map: T.func,
    }),
  ),
  reasonTypes: T.arrayOf(
    T.shape({
      id: T.number,
      description: T.string,
      detail: T.string,
      isJustificationRequired: T.bool,
      map: T.func,
    }),
  ),
};

Wizard.defaultProps = {
  auth: null,
  employees: [],
  managers: [],
  formValues: undefined,
  areas: [],
  reasons: null,
  hours: null,
  badgeTypes: [],
  hourTypes: [],
  areaTypes: [],
  reasonTypes: [],
};

IndicatorSeparator.propTypes = {
  innerProps: T.object,
};

IndicatorSeparator.defaultProps = {
  innerProps: null,
};

export const mapStateToProps = createStructuredSelector({
  formValues: getFormValues('newRequest'),
  auth: selectors.getAuth(),
});

export const mapDispatchToProps = (dispatch) => ({
  updateField: (form, field, newValue) => dispatch(change(form, field, newValue)),
});

const usingRouter = withRouter(Wizard);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);
export default withRedux;
