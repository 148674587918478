import React from 'react';

export const Oops = () => (
  <>
    <div className={'centered'}>
      <div style={{ fontSize: '100px' }}><strong>Oops</strong></div>
      The Badge Access Request System encountered an error.  Please try again later.
    </div>
  </>
);

export default Oops;
