import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import { Table, ConnectedAsync, Page, azureAuth2, Form as ReduxForm } from 'state-template';
import { createStructuredSelector } from 'reselect';
import T from 'prop-types';
import { formatRequestRecords } from 'utils/formatRequestRecords';
import { getFormValues, change } from 'redux-form';
import Oops from 'components/Oops';
import SearchPanel from './components/SearchPanel';
import { headerPadding } from '../../utils/environment';
import {
  selectAllSearchBoxes,
  unselectAllSearchBoxes,
  calculateSearchCheckboxes,
  calcuateSearchFormValues,
  caculateQueryParameters,
  disableSearchButton } from '../../utils/search';

export const Request = (props) => {
  const {
    getRequests,
    requests,
    formValues,
    updateField,
    getSearchParameters,
    searchParameters,
    heading,
    description,
    pageName,
    approverTypeIds,
    displaySearch,
    role,
    auth,
    refresh,
    clearRequests,
  } = props;

  const { REQUEST_STATE, APPROVAL_TYPES, PAGES } = window.config;

  const msalInstance = azureAuth2.useMsalInstance();

  const history = useHistory();
  const pageLocation = history.location.pathname;
  let initialValues = null;

  const [queryState, setQueryState] = useState();
  const [searchButtonDisabledState, setSearchButtonDisabledState] = useState(true);
  const [pageSizeState, setPageSize] = useState(10);
  const [totalCountState, setTotalCountState] = useState(0);
  const [currentPageState, setCurrentPageState] = useState(1);

  // This useEffect is to clear the prior loaded requests.  The reason for doing so is so the spinner displays while the
  // API is being called to get the requests.
  useEffect(() => {
    clearRequests(pageName);
  }, [refresh]);

  // This useEffect is called when the page is initially loaded (either when the page is already loaded and
  // the user clicks on the already selected icon, or the user naviagtes to the page)
  useEffect(() => {
    let queryParameters = null;
    let pageParams = {};

    if (msalInstance.isAuthenticated === true) {
      if (requests && requests.status !== 'loading') {
        pageParams = {};

        if (history.action === 'POP') {
          setCurrentPageState(requests.currentPage);
          setPageSize(requests.pageSize);
          pageParams = {
            pageSize: requests.pageSize,
            currentPage: requests.currentPage,
          };
        } else {
          setCurrentPageState(1);
          setPageSize(requests.pageSize);
          pageParams = {
            pageSize: requests.pageSize,
            currentPage: 1,
          };
        }

        const approvalTypes = [];

        if (pageName === PAGES.MY_REQUESTS) {
          approvalTypes.push(APPROVAL_TYPES.SUBMITTER);
        }
        if (pageName === PAGES.MANAGEMENT) {
          approvalTypes.push(APPROVAL_TYPES.MANAGER);
        }
        if (pageName === PAGES.MY_APPROVAL) {
          approvalTypes.push(APPROVAL_TYPES.RECIPIENT);
        }

        queryParameters = caculateQueryParameters(requests, calculateSearchCheckboxes(searchParameters), history, pageParams, approvalTypes, requests.sortOrder, requests.sortField);
        getRequests({ queryParameters, msalInstance, pageName });
      } else {
        // console.log('without requests');
        setCurrentPageState(1);
        setPageSize(10);
        pageParams = {};
        pageParams = {
          pageSize: 10,
          currentPage: 1,
        };

        let parameters = {};
        const approvalTypes = [];

        // Set search values
        if (pageName === PAGES.SECURITY) {
          parameters = {
            pendingSecurityCheckbox: [REQUEST_STATE.PENDING_SECURITY_APPROVAL],
          };
        }

        if (pageName === PAGES.GUARD) {
          parameters = {
            approvedCheckbox: [REQUEST_STATE.APPROVED],
          };
        }

        if (pageName === PAGES.MY_REQUESTS) {
          parameters = {
            pendingRecipientCheckbox: [REQUEST_STATE.PENDING_RECIPIENT_APPROVAL],
            pendingManagerCheckbox: [REQUEST_STATE.PENDING_MANAGER_APPROVAL],
            pendingSupervisorCheckbox: [REQUEST_STATE.PENDING_SUPERVISOR_APPROVAL],
            pendingSecurityCheckbox: [REQUEST_STATE.PENDING_SECURITY_APPROVAL],
          };
          approvalTypes.push(APPROVAL_TYPES.SUBMITTER);
        }

        if (pageName === PAGES.MY_APPROVAL) {
          parameters = {
            pendingRecipientCheckbox: [1],
          };
          approvalTypes.push(APPROVAL_TYPES.RECIPIENT);
        }

        if (pageName === PAGES.MANAGEMENT) {
          parameters = {
            pendingManagerCheckbox: [REQUEST_STATE.PENDING_MANAGER_APPROVAL],
            pendingSupervisorCheckbox: [REQUEST_STATE.PENDING_SUPERVISOR_APPROVAL],
          };
          approvalTypes.push(APPROVAL_TYPES.MANAGER);
          approvalTypes.push(APPROVAL_TYPES.SUPERVISOR);
        }

        queryParameters = caculateQueryParameters(null, calculateSearchCheckboxes(parameters), history, pageParams, approvalTypes, 'DESC', 'id');
        getRequests({ queryParameters, msalInstance, pageName });
      }
    }
    // eslint-disable-next-line
  }, [refresh]);


  useEffect(() => {
    if (searchParameters) {
      setQueryState(calculateSearchCheckboxes(searchParameters));
      setSearchButtonDisabledState(false);
    }
  }, [searchParameters]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (requests) {
      setTotalCountState(requests.totalCount);
    }
  }, [requests]);

  useEffect(() => {
    if (formValues) {
      setSearchButtonDisabledState(disableSearchButton(formValues));
    }
  }, [formValues]);

  // useEffect(() => {
  //   console.log('useEffect - queryState');
  //   if (initialLoad === false) {
  //     if (queryState) {
  //       console.log('call using queryState');
  //       let pageParams = {};
  //       console.log('SDF', history.action);
  //       if (history.action === 'POP') {
  //         setCurrentPageState(requests.currentPage);
  //         setPageSize(requests.pageSize);
  //         pageParams = {
  //           pageSize: requests.pageSize,
  //           currentPage: requests.currentPage,
  //         };

  //         console.log(pageParams);

  //         const queryParameters = caculateQueryParameters(requests, queryState, history, pageParams, approverTypeIds);

  //         getRequests({ queryParameters, msalInstance, pageName });
  //       } else
  //       if (history.action === 'PUSH') {
  //         setCurrentPageState(1);
  //         setPageSize(10);

  //         pageParams = {
  //           pageSize: 10,
  //           currentPage: 1,
  //         };

  //         const queryParameters = caculateQueryParameters(requests, queryState, history, pageParams, approverTypeIds);
  //         getRequests({ queryParameters, msalInstance, pageName });
  //       } else {
  //         pageParams = {
  //           pageSize: 10,
  //           currentPage: 1,
  //         };
  //         const queryParameters = caculateQueryParameters(requests, queryState, history, pageParams, approverTypeIds);
  //         getRequests({ queryParameters, msalInstance, pageName });
  //       }
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [queryState]);

  const onTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    let pageParams = {};

    setCurrentPageState(page);
    setPageSize(sizePerPage);

    pageParams = {
      pageSize: sizePerPage,
      currentPage: page,
    };


    if (queryState) {
      let queryParameters = null;
      queryParameters = caculateQueryParameters(requests, queryState, history, pageParams, approverTypeIds, sortOrder, sortField);

      getRequests({ queryParameters, msalInstance, pageName });
    }
  };

  const clickRow = (e, row) => {
    history.push({ pathname: '/request-form', row, pageLocation });
  };

  const search = () => {
    setCurrentPageState(1);
    const searchParams = calcuateSearchFormValues(formValues);
    getSearchParameters({ searchParams, pageName });
    setQueryState(calculateSearchCheckboxes(formValues));

    clearRequests(pageName);

    const pageParams = {
      pageSize: 10,
      currentPage: 1,
    };

    let queryParameters = null;

    queryParameters = caculateQueryParameters(requests, calculateSearchCheckboxes(formValues), history, pageParams, approverTypeIds);

    getRequests({ queryParameters, msalInstance, pageName });
  };

  if (searchParameters) {
    initialValues = calcuateSearchFormValues(searchParameters);
  }

  const renderTable = () => {
    if (requests !== null && requests.items && requests.items.length > 0) {
      const defaultSorted = [{
        dataField: 'id',
        order: 'desc',
      }];
      return (
        <ConnectedAsync delay={0}>
          <div style={{ margin: 'auto', width: '75%', textAlign: 'center', padding: '5px' }}>
            Click on a row below to see the details about a specific request or to take an action on the request, if applicable.
          </div>
          <div style={{ marginTop: '0px' }}>
            Total Results Found
            {': '}
            {totalCountState}
          </div>
          <div style={{ paddingTop: '20px' }}>
            <Table
              defaultSorted={defaultSorted}
              remote
              hideSearch
              data-test={'results-table'}
              page={currentPageState}
              sizePerPage={pageSizeState}
              totalSize={totalCountState}
              onTableChange={onTableChange}
              data={formatRequestRecords(requests, role, auth)}
              columns={[
                { dataField: 'id', hidden: true, text: '' },
                { dataField: 'id', text: 'ID', sort: true },
                { dataField: 'statusType.description', text: 'Status', sort: true },
                { dataField: 'submitter.name', text: 'Submitter', sort: true, minWidth: 200, maxWidth: 250 },
                { dataField: 'recipient.name', text: 'Recipient', sort: true, minWidth: 200, maxWidth: 250 },
                { dataField: 'approver.name', text: 'Approver', sort: true, minWidth: 200, maxWidth: 250 },
                { dataField: 'security.name', text: 'Security', minWidth: 200, maxWidth: 250 },
                { dataField: 'expirationDate', text: 'Expiration' },
              ]}
              rowEvents={{ onClick: clickRow }}
            />
          </div>
        </ConnectedAsync>
      );
    }

    return (
      <div>
        <div style={{ textAlign: 'center', fontSize: '20px' }}>
          <div style={{ marginTop: '10px' }}>
            <img alt={'No requests'} style={{ height: '100px' }} src={'../imgs/office-145960_640.png'} />
          </div>
          No requests found
        </div>
      </div>
    );
  };

  if (requests && requests.status && requests.status === 'loading') {
    return (
      <div className={headerPadding()}>
        <Page title={heading}>
          <ReduxForm
            form={pageName}
            onSubmit={search}
            initialValues={initialValues}
          >

            <p style={{ border: '1px', borderStyle: 'solid', padding: '5px', backgroundColor: '#e5f0f4' }}>
              {description}
            </p>
            <br />
            {displaySearch && (
            <SearchPanel
              selectAllSearch={() => selectAllSearchBoxes(updateField, pageName)}
              clearSearch={() => unselectAllSearchBoxes(updateField, pageName)}
              searchButtonDisabled={searchButtonDisabledState}
            />
            )}
          </ReduxForm>
          <div className={'spinner-fixed'} />
        </Page>
      </div>
    );
  }

  if (requests && requests.status && requests.status >= 300) {
    return (
      <div className={headerPadding()}>
        <Page title={heading}>
          <Oops />
        </Page>
      </div>
    );
  }

  return (
    <div className={headerPadding()}>
      <Page title={heading}>
        <ReduxForm
          form={pageName}
          onSubmit={search}
          initialValues={initialValues}
        >

          <p style={{ border: '1px', borderStyle: 'solid', padding: '5px', backgroundColor: '#e5f0f4' }}>
            {description}
          </p>
          <br />

          {/* <PanelButton
            text={'Search'}
            onClick={toggleSearch}
            isOpen={searchOpenState}
            data-test={'toggle-search'}
          />
          {searchOpenState && ( */}
          {displaySearch && (
          <SearchPanel
            selectAllSearch={() => selectAllSearchBoxes(updateField, pageName)}
            clearSearch={() => unselectAllSearchBoxes(updateField, pageName)}
            searchButtonDisabled={searchButtonDisabledState}
          />
          )}
        </ReduxForm>
        {renderTable()}
      </Page>
    </div>
  );
};

Request.propTypes = {
  requests: T.oneOfType([
    T.object,
    T.string,
  ]),
  getRequests: T.func.isRequired,
  formValues: T.object,
  updateField: T.func.isRequired,
  heading: T.string.isRequired,
  description: T.string.isRequired,
  pageName: T.string.isRequired,
  getSearchParameters: T.func.isRequired,
  searchParameters: T.object,
  approverTypeIds: T.array,
  displaySearch: T.bool,
  role: T.string.isRequired,
  refresh: T.number.isRequired,
  auth: T.object.isRequired,
  clearRequests: T.func.isRequired,
};

Request.defaultProps = {
  requests: null,
  formValues: {},
  approverTypeIds: [],
  searchParameters: null,
  displaySearch: false,
};

export const mapStateToProps = (state, form) => createStructuredSelector({
  requests: selectors.getRequests(form.pageName),
  searchParameters: selectors.getSearchParameters(form.pageName),
  auth: selectors.getAuth(),
  formValues: getFormValues(form.pageName),
});

export const mapDispatchToProps = (dispatch) => ({
  getRequests: (state) => dispatch(actions.getRequests(state)),
  clearRequests: (state) => dispatch(actions.clearRequests(state)),
  getSearchParameters: (state) => dispatch(actions.getSearchParameters(state)),
  updateField: (form, field, newValue) => dispatch(change(form, field, newValue)),
});

const usingRouter = withRouter(Request);
const usingRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);
export default usingRedux;
