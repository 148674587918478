import React from 'react';
import { FieldInput } from 'state-template';
import T from 'prop-types';
import schema from '../schema';

export const ApproverDetailsCard = (props) => {
  const {
    displayFields,
    role,
  } = props;

  const { approver } = schema;

  return (
    <>
      <div className={'requestFormCard'}>
        <h3>Approver Details</h3>
        <div className={'row'}>
          <div className={'col-md-4'}>
            <FieldInput
              {...approver.name}
              disabled={displayFields.approver[role].disabled}
            />
          </div>
          <div className={'col-md-4'}>
            <FieldInput
              {...approver.email}
              disabled={displayFields.approver[role].disabled}
            />
          </div>
          <div className={'col-md-4'}>
            <FieldInput
              {...approver.phone}
              disabled={displayFields.approver[role].disabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};

ApproverDetailsCard.propTypes = {
  displayFields: T.object.isRequired,
  role: T.string.isRequired,
};

export default ApproverDetailsCard;
