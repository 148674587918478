import React, { useState, useEffect } from 'react';
import { Page, Button, azureAuth2, Form as ReduxForm } from 'state-template';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import T from 'prop-types';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { reset, getFormValues, isValid } from 'redux-form';
import Oops from 'components/Oops';
import theme from '../../../style/components/mui';
import Wizard from './Wizard';
// import { useMsalInstance } from '../../../utils/aureAuth2';
import { headerPadding } from '../../../utils/environment';
import RefreshPendingCounts from '../../../components/RefreshPendingCounts';
import ErrorDialog from '../../../components/ErrorDialog';
import SuccessDialog from '../../../components/SuccessDialog';
import ProcessingDialog from '../../../components/ProcessingDialog';

const getSteps = () => ['Recipient', 'Access'];

export const NewRequestPageContent = (props) => {
  const {
    formValues,
    badgeTypes,
    getEmployees,
    employees,
    managers,
    hourTypes,
    areaTypes,
    reasonTypes,
    resetForm,
    postRequest,
    postRequestSuccess,
    formValid,
    resetRequestSuccess,
    getPendingSecurityApprovals,
    getPendingManagementApprovals,
    refresh,
  } = props;

  const msalInstance = azureAuth2.useMsalInstance();
  const steps = getSteps();
  const { stepperTheme } = theme;
  const isStepOptional = (step) => step === 3;

  const [reasons, setReason] = useState();
  const [areas, setAreas] = useState([]);
  const [hours, setHours] = useState();
  const [processingRequest, setProcessingRequest] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const isStepSkipped = (step) => skipped.has(step);

  useEffect(() => {
    window.scrollTo(0, 0);

    getEmployees(msalInstance);
    setProcessingRequest(false);
    resetForm();
    setActiveStep(0);
    // eslint-disable-next-line
  }, [refresh]);

  useEffect(() => {
    setProcessingRequest(false);
    getPendingSecurityApprovals({ msalInstance });
    getPendingManagementApprovals({ msalInstance });
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [postRequestSuccess]);

  useEffect(() => {
    resetRequestSuccess();
  }, [resetRequestSuccess]);


  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (activeStep === 0) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }

    if (activeStep === 1) {
      const record = {};

      if (formValues.recepientDetails) {
        record.userID = formValues.recepientDetails.name.value;
        record.licensePlate = formValues.recepientDetails.licensePlate;
        record.managerId = formValues.recepientDetails.approvingManager.value;
      }

      record.reasonTypeID = formValues.reasons.reasonRadioButton;

      if (formValues.reasons.justification !== undefined) {
        record.reasonTypeJustification = formValues.reasons.justification;
      }

      record.hourTypeID = formValues.hours.hoursRadioButton;

      if (formValues.hours.justification !== undefined) {
        record.hourTypeJustification = formValues.hours.justification;
      }

      const areasSection = [];

      formValues.areas.areasCheckboxes.forEach((value) => {
        const entry = {};
        entry.areaTypeID = value;

        const found = areaTypes.find((x) => x.id === value);
        if (found.isJustificationRequired) {
          entry.justification = formValues.areas[`justification_${value}`];
        }

        areasSection.push(entry);
      });

      record.areas = areasSection;

      postRequest({ record, msalInstance });
      setProcessingRequest(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    resetForm();
    resetRequestSuccess();
    setReason();
    setAreas([]);
    setHours();
    getEmployees(msalInstance);
  };

  const resetPostRequest = () => {
    resetRequestSuccess();
  };

  const renderResetFormButton = () => {
    if (activeStep < 2) {
      return (
        <Button
          name={'reset'}
          className={'btn-custom-red'}
          style={{ float: 'right' }}
          onClick={handleReset}
        >
        Reset Form
        </Button>
      );
    }

    return (
      null
    );
  };

  const displayProcessing = () => {
    if (processingRequest) {
      return (
        <ProcessingDialog />
      );
    }

    return null;
  };

  const displayResult = () => {
    if (postRequestSuccess) {
      if (postRequestSuccess === 'SUCCESS') {
        const success = {};
        success.title = 'Success';
        success.status = 'Successfull Submission';
        success.statusText = 'The request has been successfully submitted';
        success.icon = 'check-fill';
        success.iconColor = 'green';
        return (
          <SuccessDialog
            success={success}
            handleReset={handleReset}
          />
        );
      }

      if (postRequestSuccess.status) {
        const err = [];
        if (postRequestSuccess.errorMessage && postRequestSuccess.errorMessage.errors) {
          const keys = Object.keys(postRequestSuccess.errorMessage.errors);

          keys.forEach((key) => {
            err.push(`${postRequestSuccess.errorMessage.errors[key]}`);
          });
        }

        const error = {};
        error.title = 'Unable to Submit Request';
        error.status = 'Unable to submit request';
        error.statusText = err;
        error.icon = 'warning-triangle';
        error.iconColor = '#b8291f';

        return (
          <ErrorDialog
            error={error}
            resetData={resetPostRequest}
          />
        );
      }
    }

    return (
      null
    );
  };

  if (employees == null || managers == null) {
    return (
      <div className={'spinner'} />
    );
  }

  if ((badgeTypes && badgeTypes.status && badgeTypes.status >= 300)
  || (employees && employees.status && employees.status >= 300)
  || (managers && managers.status && managers.status >= 300)
  || (hourTypes && hourTypes.status && hourTypes.status >= 300)
  || (areaTypes && areaTypes.status && areaTypes.status >= 300)
  || (reasonTypes && reasonTypes.status && reasonTypes.status >= 300)) {
    return (
      <div className={headerPadding()}>
        <Page title={'New Request'}>
          <Oops />
        </Page>
      </div>
    );
  }

  return (
    <div className={headerPadding()}>
      <RefreshPendingCounts />
      <Page title={'New Request'}>
        <div>
          <ReduxForm
            form={'newRequest'}
            onSubmit={handleNext}
          >
            <p style={{ border: '1px', borderStyle: 'solid', padding: '5px', backgroundColor: '#e5f0f4' }}>
              The wizard will guide you through the process to submit a badge request.  Only one pending badge request
              is allowed at a time for a given employee.  You may choose an employee only within your unit.  If a manager
              has not been identified for your unit, you may choose another manager within CDT.
            </p>
            <br />
            {renderResetFormButton()}
            <div style={{ wordBreak: 'break-word', paddingTop: '40px' }}>
              <MuiThemeProvider theme={stepperTheme}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                      labelProps.optional = 'optional';
                    }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </MuiThemeProvider>
            </div>
            <Wizard
              activeStep={activeStep}
              badgeTypes={badgeTypes}
              employees={employees}
              managers={managers}
              areas={areas}
              setAreas={setAreas}
              reasons={reasons}
              setReason={setReason}
              hours={hours}
              setHours={setHours}
              hourTypes={hourTypes}
              areaTypes={areaTypes}
              reasonTypes={reasonTypes}
            />
            <div>
              {displayProcessing()}
              {displayResult()}
              <div style={{ textAlign: 'center' }}>
                <Button
                  disabled={activeStep === 0}
                  className={'btn-custom-teal-outline'}
                  onClick={handleBack}
                  id={'handleBack'}
                >
                      Back
                </Button>
                {' '}
                <Button
                  className={'btn-custom-teal-outline'}
                  type={'submit'}
                  // Using Redux reset() disables the required field validation until a user changes a required field.
                  // Therefore, added check to see if recepient details is null before enabling "Next" button.
                  disabled={!formValid || formValues.recepientDetails == null || formValues.recepientDetails.approvingManager == null || formValues.recepientDetails.name.value === formValues.recepientDetails.approvingManager.value}
                >
                  {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                </Button>
              </div>
            </div>
          </ReduxForm>
        </div>
      </Page>
    </div>
  );
};

NewRequestPageContent.propTypes = {
  employees: T.oneOfType([
    T.object,
    T.array]),
  managers: T.oneOfType([
    T.object,
    T.array]),
  badgeTypes: T.oneOfType([
    T.object,
    T.array]),
  hourTypes: T.oneOfType([
    T.object,
    T.array]),
  areaTypes: T.oneOfType([
    T.object,
    T.array]),
  reasonTypes: T.oneOfType([
    T.object,
    T.array]),
  resetForm: T.func.isRequired,
  formValues: T.object,
  postRequest: T.func.isRequired,
  postRequestSuccess: T.oneOfType([
    T.string,
    T.object,
  ]),
  formValid: T.bool,
  resetRequestSuccess: T.func.isRequired,
  getPendingSecurityApprovals: T.func.isRequired,
  getPendingManagementApprovals: T.func.isRequired,
  getEmployees: T.func.isRequired,
  refresh: T.number.isRequired,
};

NewRequestPageContent.defaultProps = {
  employees: [],
  managers: [],
  badgeTypes: [],
  hourTypes: [],
  areaTypes: [],
  reasonTypes: [],
  formValues: {},
  postRequestSuccess: {},
  formValid: false,
};

export const mapStateToProps = createStructuredSelector({
  badgeTypes: selectors.getBadgeTypes(),
  employees: selectors.getEmployees(),
  managers: selectors.getManagers(),
  hourTypes: selectors.getHourTypes(),
  areaTypes: selectors.getAreaTypes(),
  reasonTypes: selectors.getReasonTypes(),
  postRequestSuccess: selectors.getPostRequest(),
  formValues: getFormValues('newRequest'),
  formValid: isValid('newRequest'),
});

export const mapDispatchToProps = (dispatch) => ({
  resetForm: () => dispatch(reset('newRequest')),
  postRequest: (request) => dispatch(actions.postRequest(request)),
  getEmployees: (request) => dispatch(actions.getEmployees(request)),
  resetRequestSuccess: () => dispatch(actions.postRequestSuccess(null)),
  getPendingSecurityApprovals: (request) => dispatch(actions.getPendingSecurityApprovals(request)),
  getPendingManagementApprovals: (request) => dispatch(actions.getPendingManagementApprovals(request)),
});

const usingRouter = withRouter(NewRequestPageContent);
const usingRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);
export default usingRedux;
