import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { withAsync, api, azureAuth2 } from 'state-template';
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode';
import * as selectors from 'redux/selectors';
// import { formatAPIParams2, callMsGraph } from '../utils/aureAuth2';
import * as actions from './actions';
import * as C from './constants';

const { EXAMPLE_API, ROLES, REQUEST_STATE, PAGES } = window.config;

export function* getExampleData() {
  const url = `${EXAMPLE_API}/todos`;
  const data = yield call(api.request, url);
  yield put(actions.getExampleDataSuccess(data));
}

export function* getAuth(input) {
  yield put(actions.getAuthSuccess(input));
}

export function* getEmployees(msal) {
  const { BARS_API, API_SCOPES } = window.config;

  try {
    let params = {};
    let url = null;
    params = yield azureAuth2.formatAPIParams2(msal.payload, 'GET', API_SCOPES);
    url = `${BARS_API}/employee`;
    const data = yield api.request(url, params);
    yield put(actions.getEmployeesSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getEmployees()', error);
    yield put(actions.getEmployeesSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* getManagers(msal) {
  const { BARS_API, API_SCOPES } = window.config;

  try {
    let params = {};
    let url = null;
    const managers = [];
    params = yield azureAuth2.formatAPIParams2(msal.payload, 'GET', API_SCOPES);
    url = `${BARS_API}/employee/managers`;
    const data = yield api.request(url, params);
    data.forEach((manager) => {
      const x = {
        value: manager.id,
        label: `${manager.firstName} ${manager.lastName}`,
      };
      managers.push(x);
    });

    yield put(actions.getManagersSuccess(managers));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getManagers()', error);
    yield put(actions.getManagersSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* getEmployeeManager(input) {
  const { BARS_API, API_SCOPES } = window.config;

  try {
    let params = {};
    let url = null;
    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'GET', API_SCOPES);
    url = `${BARS_API}/employee/${input.payload.id}/manager`;
    const data = yield api.request(url, params);
    yield put(actions.getEmployeeManagerSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getEmployeeManager()', error);
    yield put(actions.getEmployeeManagerSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* getHourTypes(msal) {
  const { BARS_API, API_SCOPES } = window.config;

  try {
    let params = {};
    let url = null;
    params = yield azureAuth2.formatAPIParams2(msal.payload, 'GET', API_SCOPES);
    url = `${BARS_API}/hourtypes`;
    const data = yield api.request(url, params);
    yield put(actions.getHourTypesSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getHourTypes()', error);
    yield put(actions.getHourTypesSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* getAreaTypes(msal) {
  const { BARS_API, API_SCOPES } = window.config;

  try {
    let params = {};
    let url = null;
    params = yield azureAuth2.formatAPIParams2(msal.payload, 'GET', API_SCOPES);
    url = `${BARS_API}/areatypes`;
    const data = yield api.request(url, params);
    yield put(actions.getAreaTypesSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getAreaTypes()', error);
    yield put(actions.getAreaTypesSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* getReasonTypes(msal) {
  const { BARS_API, API_SCOPES } = window.config;

  try {
    let params = {};
    let url = null;
    params = yield azureAuth2.formatAPIParams2(msal.payload, 'GET', API_SCOPES);
    url = `${BARS_API}/reasontypes`;
    const data = yield api.request(url, params);
    yield put(actions.getReasonTypesSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getReasonTypes()', error);
    yield put(actions.getReasonTypesSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* clearRequests(input) {
  yield put(actions.getSecurityRequestsSuccess({ status: 'loading' }));

  if (input.payload === PAGES.SECURITY) {
    yield put(actions.getSecurityRequestsSuccess({ status: 'loading' }));
  }
  if (input.payload === PAGES.MY_REQUESTS) {
    yield put(actions.getMyRequestsSuccess({ status: 'loading' }));
  }
  if (input.payload === PAGES.MY_APPROVAL) {
    yield put(actions.getMyApprovalsSuccess({ status: 'loading' }));
  }
  if (input.payload === PAGES.GUARD) {
    yield put(actions.getGuardRequestsSuccess({ status: 'loading' }));
  }
  if (input.payload === PAGES.MANAGEMENT) {
    yield put(actions.getManagementRequestsSuccess({ status: 'loading' }));
  }
}

export function* getRequests(input) {
  const { BARS_API, API_SCOPES } = window.config;
  try {
    let params = {};
    let url = null;
    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'GET', API_SCOPES);
    url = `${BARS_API}/request?${input.payload.queryParameters}`;
    const data = yield api.request(url, params);

    if (input.payload.pageName === PAGES.SECURITY) {
      yield put(actions.getSecurityRequestsSuccess(data));
    }
    if (input.payload.pageName === PAGES.MY_REQUESTS) {
      yield put(actions.getMyRequestsSuccess(data));
    }
    if (input.payload.pageName === PAGES.MY_APPROVAL) {
      yield put(actions.getMyApprovalsSuccess(data));
    }
    if (input.payload.pageName === PAGES.GUARD) {
      yield put(actions.getGuardRequestsSuccess(data));
    }
    if (input.payload.pageName === PAGES.MANAGEMENT) {
      yield put(actions.getManagementRequestsSuccess(data));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    if (input.payload.pageName === PAGES.SECURITY) {
      yield put(actions.getSecurityRequestsSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
    }
    if (input.payload.pageName === PAGES.MY_REQUESTS) {
      yield put(actions.getMyRequestsSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
    }
    if (input.payload.pageName === PAGES.MY_APPROVAL) {
      yield put(actions.getMyApprovalsSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
    }
    if (input.payload.pageName === PAGES.GUARD) {
      yield put(actions.getGuardRequestsSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
    }
    if (input.payload.pageName === PAGES.MANAGEMENT) {
      yield put(actions.getManagementRequestsSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
    }
  }
}

export function* getPendingSecurityApprovals(input) {
  const { BARS_API, API_SCOPES } = window.config;

  try {
    let params = {};
    let url = null;

    const queryParameters = `StatusTypeIds=${REQUEST_STATE.PENDING_SECURITY_APPROVAL}`;

    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'GET', API_SCOPES);
    url = `${BARS_API}/request?${queryParameters}`;
    const data = yield api.request(url, params);
    yield put(actions.getPendingSecurityApprovalsSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getPendingSecurityApprovals()', error);
    yield put(actions.getPendingSecurityApprovalsSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* getPendingManagementApprovals(input) {
  const { BARS_API, API_SCOPES, APPROVAL_TYPES } = window.config;

  try {
    let params = {};
    let url = null;

    const queryParameters = `StatusTypeIds=${REQUEST_STATE.PENDING_MANAGER_APPROVAL}&StatusTypeIds=${REQUEST_STATE.PENDING_SUPERVISOR_APPROVAL}&ApproverTypeIds=${APPROVAL_TYPES.MANAGER}&ApproverTypeIds=${APPROVAL_TYPES.SUPERVISOR}`;

    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'GET', API_SCOPES);
    url = `${BARS_API}/request?${queryParameters}`;
    const data = yield api.request(url, params);
    yield put(actions.getPendingManagementApprovalsSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getPendingManagmementApprovals()', error)
    yield put(actions.getPendingManagementApprovalsSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* getPendingMyApprovals(input) {
  const { BARS_API, API_SCOPES, APPROVAL_TYPES } = window.config;

  try {
    let params = {};
    let url = null;

    const queryParameters = `StatusTypeIds=${REQUEST_STATE.PENDING_RECIPIENT_APPROVAL}&ApproverTypeIds=${APPROVAL_TYPES.RECIPIENT}`;

    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'GET', API_SCOPES);
    url = `${BARS_API}/request?${queryParameters}`;
    const data = yield api.request(url, params);
    yield put(actions.getPendingMyApprovalsSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getPendingMyApprovals()', error)
    yield put(actions.getPendingMyApprovalsSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* getMyRequests(input) {
  const { BARS_API, API_SCOPES } = window.config;

  try {
    let params = {};
    let url = null;

    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'GET', API_SCOPES);
    url = `${BARS_API}/request?${input.payload.queryParameters}&PageSize=50`;
    const data = yield api.request(url, params);
    yield put(actions.getMyRequestsSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getPendingSecurityApprovals()', error);
    yield put(actions.getMyRequestsSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* getMyApprovals(input) {
  const { BARS_API, API_SCOPES } = window.config;

  try {
    let params = {};
    let url = null;
    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'GET', API_SCOPES);
    url = `${BARS_API}/request?${input.payload.queryParameters}&PageSize=50`;
    const data = yield api.request(url, params);
    yield put(actions.getMyApprovalsSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getMyApprovals()', error);
    yield put(actions.getMyApprovalsSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* getApprovedRequests(input) {
  const { BARS_API, API_SCOPES } = window.config;

  try {
    let params = {};
    let url = null;
    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'GET', API_SCOPES);
    url = `${BARS_API}/request?StatusTypeIds=${REQUEST_STATE.APPROVED}`;
    const data = yield api.request(url, params);
    yield put(actions.getApprovedRequestsSuccess(data));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('getMyApprovals()', error);
    yield put(actions.getApprovedRequestsSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* postRequest(input) {
  const { BARS_API, API_SCOPES } = window.config;
  let params = {};
  let url = null;
  params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'POST', API_SCOPES);
  url = `${BARS_API}/request`;
  params.body = JSON.stringify(input.payload.record);

  try {
    yield api.request(url, params);
    yield put(actions.postRequestSuccess('SUCCESS'));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('postRequest()', error);
    yield put(actions.postRequestSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* putApprove(input) {
  const newParams = {
    headers: {
      'Content-Type': 'application/json-patch+json',
    },
    method: 'put',
  };

  const { BARS_API, API_SCOPES } = window.config;
  let params = {};
  let url = null;
  try {
    params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'PUT', API_SCOPES);
    // Hack -- Until above state template 'formatAPIParams2' is updated.
    Object.values(params.headers).map((item, i) => {
      if (i === 1) {
        newParams.headers.Authorization = `${item}`;
      }
      return null;
    });

    newParams.body = JSON.stringify(input.payload.record);
    url = `${BARS_API}/request/${input.payload.id}/approve`;
    yield api.request(url, newParams);
    yield put(actions.putApproveSuccess('SUCCESS'));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('putApprove()', error);
    yield put(actions.putApproveSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* putCancel(input) {
  const { BARS_API, API_SCOPES } = window.config;

  let params = {};
  let url = null;
  params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'PUT', API_SCOPES);
  url = `${BARS_API}/request/${input.payload.record.id}/cancel?cancellationReason=${input.payload.record.cancelRejectJustification}`;

  try {
    yield api.request(url, params);
    yield put(actions.putCancelSuccess('SUCCESS'));
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.log('putCancel()', error);
    yield put(actions.putCancelSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* putEscalate(input) {
  const { BARS_API, API_SCOPES } = window.config;
  let params = {};
  let url = null;
  params = yield azureAuth2.formatAPIParams2(input.payload.msalInstance, 'PUT', API_SCOPES);
  url = `${BARS_API}/request/${input.payload.record.id}/escalate?supervisorId=${input.payload.record.supervisorId}`;

  try {
    yield api.request(url, params);
    yield put(actions.putEscalateSuccess('SUCCESS'));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('putEscalate()', error);
    yield put(actions.putEscalateSuccess({ status: error.status, statusText: error.statusText, body: error.body }));
  }
}

export function* getRefreshPendingCounts(params) {
  const auth = yield select(selectors.getAuth());

  let input = {};
  try {
    if (auth) {
      if (auth.roles.find((role) => role === ROLES.MANAGER)) {
        input = {};
        input.payload = {
          msalInstance: params.payload.msalInstance,
        };
        yield getPendingManagementApprovals(input);
      }

      if (auth.roles.find((role) => role === ROLES.BARS_SECURITY)) {
        input = {};
        input.payload = {
          msalInstance: params.payload.msalInstance,
        };
        yield getPendingSecurityApprovals(input);
      }

      input = {};
      input.payload = {
        msalInstance: params.payload.msalInstance,
      };
      yield getPendingMyApprovals(input);
    }
  } catch (error) {
    // do nothing
  }
}

export function* getSearchParameters(input) {
  try {
    if (input.payload.pageName === PAGES.SECURITY) {
      yield put(actions.getSecuritySearchParametersSuccess(input.payload.searchParams));
    } else if (input.payload.pageName === PAGES.MY_REQUESTS) {
      yield put(actions.getMyRequestsSearchParametersSuccess(input.payload.searchParams));
    } else if (input.payload.pageName === PAGES.MY_APPROVAL) {
      yield put(actions.getMyApprovalSearchParametersSuccess(input.payload.searchParams));
    } else if (input.payload.pageName === PAGES.GUARD) {
      yield put(actions.getGuardSearchParametersSuccess(input.payload.searchParams));
    } else if (input.payload.pageName === PAGES.MANAGEMENT) {
      yield put(actions.getManagementSearchParametersSuccess(input.payload.searchParams));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    if (input.payload.pageName === PAGES.SECURITY) {
      yield put(actions.getSecuritySearchParametersSuccess(error));
    } else if (input.payload.pageName === PAGES.MY_REQUESTS) {
      yield put(actions.getMyRequestsSearchParametersSuccess(error));
    } else if (input.payload.pageName === PAGES.MY_APPROVAL) {
      yield put(actions.getMyApprovalSearchParametersSuccess(error));
    } else if (input.payload.pageName === PAGES.GUARD) {
      yield put(actions.getGuardSearchParametersSuccess(error));
    } else if (input.payload.pageName === PAGES.MANAGEMENT) {
      yield put(actions.getManagementSearchParametersSuccess(error));
    }
  }
}

export function* appToken(payload) {
  const { apiScopes, msalInstance } = payload;

  const app = {
    scopes: apiScopes,
    account: msalInstance.accounts[0],
  };

  let accessToken = null;

  yield msalInstance.instance.acquireTokenSilent(app).then((response) => {
    accessToken = response.accessToken;
  }).catch((e) => {
    // eslint-disable-next-line no-console
    console.log('appToken()', e);
    msalInstance.instance.acquireTokenSilent(app).then((response) => {
      accessToken = response.accessToken;
    });
  });

  return accessToken;
}

export function* initApp(msal) {
  let graphData = null;
  let appAccessToken = null;

  if (msal.payload.msalInstance.isAuthenticated) {
    graphData = yield azureAuth2.callMsGraph(msal.payload);
    appAccessToken = yield appToken(msal.payload);

    // Required structure to mimic reducer's payload object
    const msalInfo = {
      payload: {
        accounts: msal.payload.msalInstance.accounts,
        instance: msal.payload.msalInstance.instance,
      },
    };

    if (graphData) {
      graphData.isAuthenticated = true;
      graphData.appAccessToken = appAccessToken;

      graphData.roles = jwt_decode(appAccessToken).roles;
      yield getAuth(graphData);
    }

    const auth = yield select(selectors.getAuth());
    let input = {};

    if (auth.isAuthenticated) {
      input = {};
      input.payload = {
        msalInstance: msalInfo.payload,
      };
      yield getPendingMyApprovals(input);

      if (auth.roles) {
        if (auth.roles.find((role) => role === ROLES.BARS_SECURITY)) {
        // Required structure to mimic reducer's payload object
          input = {};
          input.payload = {
            msalInstance: msalInfo.payload,
          };
          yield getPendingSecurityApprovals(input);

          try {
            input = {};
            input.payload = {
              pageName: PAGES.SECURITY,
            };
            input.payload.searchParams = {
              pendingSecurityCheckbox: [REQUEST_STATE.PENDING_SECURITY_APPROVAL],
            };
            yield getSearchParameters(input);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('getSearchParameters()', e);
          }
        }

        if (auth.roles.find((role) => role === ROLES.MANAGER)) {
          // Required structure to mimic reducer's payload object
          input = {};
          input.payload = {
            msalInstance: msalInfo.payload,
          };
          yield getPendingManagementApprovals(input);

          try {
            input = {};
            input.payload = {
              pageName: PAGES.MANAGEMENT,
            };
            input.payload.searchParams = {
              pendingManagerCheckbox: [REQUEST_STATE.PENDING_MANAGER_APPROVAL],
              pendingSupervisorCheckbox: [REQUEST_STATE.PENDING_SUPERVISOR_APPROVAL],
            };
            yield getSearchParameters(input);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('getSearchParameters()', e);
          }
        }

        if (auth.roles.find((role) => role === ROLES.BARS_GUARD)) {
          try {
            input = {};
            input.payload = {
              pageName: PAGES.GUARD,
            };
            input.payload.searchParams = {
              approvedCheckbox: [REQUEST_STATE.APPROVED],
            };
            yield getSearchParameters(input);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('getSearchParameters()', e);
          }
        }
      }
      // }

      try {
        input = {};
        input.payload = {
          pageName: PAGES.MY_REQUESTS,
        };
        input.payload.searchParams = {
          pendingRecipientCheckbox: [REQUEST_STATE.PENDING_RECIPIENT_APPROVAL],
          pendingManagerCheckbox: [REQUEST_STATE.PENDING_MANAGER_APPROVAL],
          pendingSupervisorCheckbox: [REQUEST_STATE.PENDING_SUPERVISOR_APPROVAL],
          pendingSecurityCheckbox: [REQUEST_STATE.PENDING_SECURITY_APPROVAL],
        };
        yield getSearchParameters(input);
      } catch (e) {
      // eslint-disable-next-line no-console
        console.log('getSearchParameters()', e);
      }

      try {
        input = {};
        input.payload = {
          pageName: PAGES.MY_APPROVAL,
        };
        input.payload.searchParams = {
          pendingRecipientCheckbox: [1],
        };
        yield getSearchParameters(input);
      } catch (e) {
      // eslint-disable-next-line no-console
        console.log('getSearchParameters()', e);
      }


      try {
        yield getReasonTypes(msalInfo);
      } catch (e) {
      // eslint-disable-next-line no-console
        console.log('initApp()', e);
      }

      try {
        yield getEmployees(msalInfo);
      } catch (err) {
      // eslint-disable-next-line no-console
        console.log(err);
      }

      try {
        yield getManagers(msalInfo);
      } catch (err) {
      // eslint-disable-next-line no-console
        console.log(err);
      }

      try {
        yield getHourTypes(msalInfo);
      } catch (err) {
      // eslint-disable-next-line no-console
        console.log(err);
      }

      try {
        yield getAreaTypes(msalInfo);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }
  }
}

export default function* saga() {
  yield all([
    takeEvery(C.GET_EXAMPLE_DATA, withAsync(getExampleData)),
    takeEvery(C.GET_EMPLOYEES, withAsync(getEmployees)),
    takeEvery(C.GET_MANAGERS, withAsync(getManagers)),
    takeEvery(C.GET_HOUR_TYPES, withAsync(getHourTypes)),
    takeEvery(C.GET_AREA_TYPES, withAsync(getAreaTypes)),
    takeEvery(C.GET_REASON_TYPES, withAsync(getReasonTypes)),
    takeEvery(C.GET_REQUESTS, withAsync(getRequests)),
    takeEvery(C.CLEAR_REQUESTS, withAsync(clearRequests)),
    takeEvery(C.GET_MY_REQUESTS, withAsync(getMyRequests)),
    takeEvery(C.GET_MY_APPROVALS, withAsync(getMyApprovals)),
    takeEvery(C.POST_REQUEST, withAsync(postRequest)),
    takeEvery(C.PUT_APPROVE, withAsync(putApprove)),
    takeEvery(C.PUT_CANCEL, withAsync(putCancel)),
    takeEvery(C.PUT_ESCALATE, withAsync(putEscalate)),
    takeEvery(C.INIT_APP, withAsync(initApp)),
    takeEvery(C.GET_AUTH, withAsync(getAuth)),
    takeEvery(C.GET_PENDING_SECURITY_APPROVALS, withAsync(getPendingSecurityApprovals)),
    takeEvery(C.GET_PENDING_MANAGEMENT_APPROVALS, withAsync(getPendingManagementApprovals)),
    takeEvery(C.GET_PENDING_MANAGEMENT_APPROVALS, withAsync(getPendingMyApprovals)),
    takeEvery(C.GET_APPROVED_REQUESTS, withAsync(getApprovedRequests)),
    takeEvery(C.GET_EMPLOYEE_MANAGER, withAsync(getEmployeeManager)),
    takeEvery(C.GET_SEARCH_PARAMETERS, withAsync(getSearchParameters)),
    takeEvery(C.GET_REFRESH_PENDING_COUNTS, withAsync(getRefreshPendingCounts)),
  ]);
}
