import React from 'react';
import HomePage from 'containers/HomePage';
import HelpPage from 'containers/HelpPage';
import MyRequestsPage from 'containers/MyRequestsPage';
import NewRequestPage from 'containers/NewRequestPage';
import RequestFormPage from 'containers/RequestFormPage';
import ManagementPage from 'containers/ManagementPage';
import MyApprovalsPage from 'containers/MyApprovalsPage';
import SecurityPage from 'containers/SecurityPage';
import GuardPage from 'containers/GuardPage';
import AuthHomePage from 'containers/AuthHomePage';
import NotFoundPage from 'containers/NotFoundPage';

const { ROLES } = window.config;

export const HeaderRoutes = (auth, pendingManagementApprovals, pendingMyApprovals, pendingSecurityApprovals) => {
  const routes = [];

  const authHomePage = {
    key: 'AuthHomePage',
    name: 'Home',
    path: '/home',
    icon: 'ca-gov-icon-home',
  };

  const myRequestsPage = {
    key: 'MyRequestsPage',
    name: 'My Requests',
    path: '/my-requests',
    icon: 'ca-gov-icon-user-id',
  };

  const myApprovalsPage = {
    key: 'MyApprovalsPage',
    // name: 'My Approval',
    name:
  <span>
    <nobr>My Approval</nobr>
    {pendingMyApprovals > 0 ? (
      <span className={'pendingNumber'}>
        <span style={{ position: 'absolute', transform: 'translate(-50%,-50%)', top: '50%', left: '50%' }} />
        !
      </span>
    ) : ''}
  </span>,
    path: '/my-approvals',
    icon: 'ca-gov-icon-check-fill',
  };

  const newRequestPage = {
    key: 'NewRequestPage',
    name: 'New Request',
    path: '/new-request',
    icon: 'ca-gov-icon-plus-line',
  };

  const managementPage = {
    key: 'ManagementPage',
    name:
  <span>
    <nobr>Manager</nobr>
    {pendingManagementApprovals >= 0 ? (
      <span className={'pendingNumber'}>
        <span style={{ position: 'absolute', transform: 'translate(-50%,-50%)', top: '50%', left: '50%' }}>
          {pendingManagementApprovals}
        </span>
      </span>
    ) : ''}
  </span>,
    path: '/management',
    icon: 'ca-gov-icon-people',
  };

  const helpPage = {
    key: 'HelpPage',
    name: 'Help',
    path: '/help',
    icon: 'ca-gov-icon-question-line',
  };

  const homePage = {
    key: 'Home',
    name: 'Home',
    path: '/',
    icon: 'ca-gov-icon-home',
    component: HomePage,
  };

  const securityPage = {
    key: 'Security',
    name:
  <span>
    <nobr>Security</nobr>
    {pendingSecurityApprovals >= 0 ? (
      <span className={'pendingNumber'}>
        <span style={{ position: 'absolute', transform: 'translate(-50%,-50%)', top: '50%', left: '50%' }}>
          {pendingSecurityApprovals}
        </span>
      </span>
    ) : ''}
  </span>,
    path: '/security',
    icon: 'ca-gov-icon-shield',
    component: SecurityPage,
  };

  const guardPage = {
    key: 'Guard',
    name: 'Guard',
    path: '/guard',
    icon: 'ca-gov-icon-shield',
    component: GuardPage,
  };

  if (auth.isAuthenticated) {
    routes.push(authHomePage);
    routes.push(myRequestsPage);
    routes.push(myApprovalsPage);
    routes.push(newRequestPage);

    if (auth.roles && auth.roles.find((role) => role === ROLES.MANAGER)) {
      routes.push(managementPage);
    }

    if (auth.roles) {
      if (auth.roles.find((role) => role === ROLES.BARS_SECURITY)) {
        routes.push(securityPage);
      } else if (auth.roles.find((role) => role === ROLES.BARS_GUARD)) {
        routes.push(guardPage);
      }
    }

    routes.push(helpPage);
  } else {
    routes.push(homePage);
  }
  return routes;
};

export const Routes = (auth) => {
  const routes = [];

  const authHomePage = {
    key: 'authHomePage',
    exact: true,
    path: '/home',
    component: AuthHomePage,
    hidden: true,
  };


  const myRequestsPage = {
    key: 'myRequests',
    exact: true,
    path: '/my-requests',
    component: MyRequestsPage,
    hidden: true,
  };

  const myApprovalsPage = {
    key: 'myApprovals',
    exact: true,
    path: '/my-approvals',
    component: MyApprovalsPage,
    hidden: true,
  };

  const managementPage = {
    key: 'managementPage',
    exact: true,
    path: '/management',
    component: ManagementPage,
    hidden: true,
  };

  const requestFormPage = {
    key: 'requestForm',
    exact: true,
    path: '/request-form',
    component: RequestFormPage,
    hidden: true,
  };

  const newRequestPage = {
    key: 'newRequest',
    exact: true,
    path: '/new-request',
    component: NewRequestPage,
    hidden: true,
  };

  const helpPage = {
    key: 'help',
    exact: true,
    path: '/help',
    component: HelpPage,
    hidden: true,
  };

  const homePage = {
    key: 'home',
    name: 'Home',
    exact: true,
    path: '/',
    component: HomePage,
    hidden: true,
  };

  const securityPage = {
    key: 'securityPage',
    exact: true,
    path: '/security',
    component: SecurityPage,
    hidden: true,
  };

  const guardPage = {
    key: 'guardPage',
    exact: true,
    path: '/guard',
    component: GuardPage,
    hidden: true,
  };

  const notFoundPage = {
    key: 'notFoundPage',
    exact: true,
    path: '/not-found',
    component: NotFoundPage,
    hidden: true,
  };

  if (auth.isAuthenticated) {
    routes.push(authHomePage);
    routes.push(myRequestsPage);
    routes.push(myApprovalsPage);
    routes.push(requestFormPage);
    routes.push(newRequestPage);

    if (auth.roles && auth.roles.find((role) => role === ROLES.MANAGER)) {
      routes.push(managementPage);
    }

    if (auth.roles) {
      if (auth.roles.find((role) => role === ROLES.BARS_SECURITY)) {
        routes.push(securityPage);
      } else if (auth.roles.find((role) => role === ROLES.BARS_GUARD)) {
        routes.push(guardPage);
      }
    }

    routes.push(helpPage);
    routes.push(notFoundPage);
  } else {
    routes.push(homePage);
    routes.push(notFoundPage);
  }

  return routes;
};
