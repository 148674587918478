import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import T from 'prop-types';
import { FieldInput, FieldCheckboxes, Button, azureAuth2, ButtonRow, Form as ReduxForm } from 'state-template';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import * as actions from 'redux/actions';
import schema from '../schema';
// import { useMsalInstance } from '../../../utils/aureAuth2';

export const RejectRequestDialog = (props) => {
  const {
    formValues,
    formValuesRequest,
    putCancel,
    callingApi,
  } = props;

  const msalInstance = azureAuth2.useMsalInstance();

  const [open, setOpen] = useState(false);

  const { cancelReject, signature } = schema;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitReject = () => {
    handleClose();
    callingApi();

    const record = {};
    record.id = formValuesRequest.id;
    record.cancelRejectJustification = formValues.cancelRejectJustification;

    putCancel({ record, msalInstance });
  };

  return (
    <>
      <Button
        id={'test'}
        className={'btn-custom-teal-outline'}
        iconProps={{ name: 'close-fill' }}
        onClick={handleOpen}
      >
        Reject
      </Button>

      <Dialog
        open={open}
        maxWidth={'lg'}
        style={{ zIndex: 3000 }}
        id={'rejectRequestDialog'}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <ReduxForm
          form={'rejectRequestDialog'}
          onSubmit={submitReject}
        >
          <DialogTitle
            id={'scroll-dialog-title'}
          >
            <div style={{ textAlign: 'center' }}>
            Reject Request?
            </div>
          </DialogTitle>
          <DialogContent dividers>
            Provide the reason for rejecting this request.
            <FieldInput
              {...cancelReject.justification}
            />
            <div style={{ textAlign: 'center' }}>
              <FieldCheckboxes
                {...signature}
              />
            </div>
            <br />
            <ButtonRow style={{ textAlign: 'right' }}>
              <Button
                type={'submit'}
                className={'btn-custom-teal-outline'}
                name={'submit'}
              >
                Submit
              </Button>
              <Button
                className={'btn-custom-teal-outline'}
                onClick={handleClose}
                name={'cancel'}
              >
                Cancel
              </Button>
            </ButtonRow>
          </DialogContent>
        </ReduxForm>
      </Dialog>
    </>
  );
};

RejectRequestDialog.propTypes = {
  formValues: T.object,
  formValuesRequest: T.object,
  putCancel: T.func.isRequired,
  callingApi: T.func.isRequired,

};

RejectRequestDialog.defaultProps = {
  formValues: {},
  formValuesRequest: {},
};

export const mapStateToProps = createStructuredSelector({
  formValues: getFormValues('rejectRequestDialog'),
  formValuesRequest: getFormValues('request'),
});

export const mapDispatchToProps = (dispatch) => ({
  putCancel: (request) => dispatch(actions.putCancel(request)),
});

const usingRouter = withRouter(RejectRequestDialog);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);
export default withRedux;
