import React from 'react';
import Request from '../../components/Request';
import RefreshPendingCounts from '../../components/RefreshPendingCounts';

const { PAGES, ROLES } = window.config;

export const SecurityPage = () => (
  <>
    <RefreshPendingCounts />
    <Request
      heading={'Security'}
      description={'This list contains all the requests submitted in BARS.  By default, only the badge requests in a pending security approval state are displayed.'}
      pageName={PAGES.SECURITY}
      role={ROLES.BARS_SECURITY}
      displaySearch
      refresh={Math.random()} // Forces page to rerender by updating prop
    />
  </>
);
export default SecurityPage;
