import React from 'react';
import { ButtonRow, Button } from 'state-template';
import T from 'prop-types';
import RejectRequestDialog from './RejectRequestDialog';
import EscalateRequestDialog from './EscalateRequestDialog';
import ApproveRequestDialog from './ApproveRequestDialog';

export const ButtonsCard = (props) => {
  const {
    leftButton,
    middleButton,
    rightButton,
    currentRole,
    callingApi,
  } = props;

  const modals = {
    rejectRequestDialog: RejectRequestDialog,
    escalateRequestDialog: EscalateRequestDialog,
    approveRequestDialog: ApproveRequestDialog,
  };

  const showButtons = () => {
    const buttons = [];

    if (leftButton) {
      if (leftButton.modal) {
        const DynamicModal = modals[leftButton.modal.name];
        buttons.push(<div key={leftButton.modal.name}><DynamicModal currentRole={currentRole} callingApi={callingApi} /></div>);
      } else {
        let type = {};
        if (leftButton.type) {
          type = { type: leftButton.type };
        }
        buttons.push(<div key={leftButton.name}><Button {...type} {...rightButton}>{leftButton.label}</Button></div>);
      }
    }

    if (middleButton) {
      if (middleButton.modal) {
        const DynamicModal = modals[middleButton.modal.name];
        buttons.push(<div key={middleButton.modal.name}><DynamicModal currentRole={currentRole} callingApi={callingApi} /></div>);
      } else {
        let type = {};
        if (middleButton.type) {
          type = { type: middleButton.type };
        }
        buttons.push(<div key={middleButton.name}><Button {...type} {...rightButton}>{middleButton.label}</Button></div>);
      }
    }

    if (rightButton) {
      if (rightButton.modal) {
        const DynamicModal = modals[rightButton.modal.name];
        buttons.push(<div key={rightButton.modal.name}><DynamicModal currentRole={currentRole} callingApi={callingApi} /></div>);
      } else {
        let type = {};
        if (rightButton.type) {
          type = { type: rightButton.type };
        }
        buttons.push(<div key={rightButton.name}><Button {...type} {...rightButton}>{rightButton.label}</Button></div>);
      }
    }

    return buttons;
  };


  return (
    <ButtonRow style={{ paddingTop: '25px', display: 'flex', justifyContent: 'center' }}>
      {showButtons()}
    </ButtonRow>
  );
};

ButtonsCard.propTypes = {
  leftButton: T.object,
  middleButton: T.object,
  rightButton: T.object,
};

ButtonsCard.defaultProps = {
  leftButton: null,
  middleButton: null,
  rightButton: null,
};

export default ButtonsCard;
