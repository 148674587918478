import React, { useEffect } from 'react';
import { Page } from 'state-template';
import { headerPadding } from '../../../utils/environment';

const HelpPageContent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={headerPadding()}>
      <Page title={'Help'}>
        <h2 style={{ textAlign: 'center' }}>Information</h2>
        <h3 style={{ marginBottom: '0em' }}>About</h3>
        <p>
        The Badge Access Request System uses data from several locations including PeopleSoft,
        ServiceNow, and Azure Active Directory. This application is maintained by the Systems Management unit
        within Internal IT Services, but the data is maintained by other units.
        </p>
        <h3 style={{ marginBottom: '0em' }}>Exceptions </h3>
        <p>
          This standard applies to CDT employees and visitors that have been issued photo ID and non-photo ID badges.
          Direct any questions regarding the applicability of this standard to the Physical Security Administrator for
          clarification. Exceptions to this standard must be documented and are considered on a case-by-case basis.
        </p>
        <h3 style={{ marginBottom: '0em' }}>Auditing and Reporting</h3>
        <p>
          Auditing may be performed on an ongoing basis by the Physical Security Administrator. In the event an
          audit determines this Standard is not being applied, notification will be sent to the appropriate person
          for remediation.
        </p>
        <p>
          Any known violations of this standard must be reported to CDT’s Physical Security Administrator and the
          reporting employee’s immediate supervisor.
        </p>
        <h3 style={{ marginBottom: '0em' }}>Contact</h3>
        <p>
        If you are having trouble using this application, or want to know the process for correcting
        a data discrepency, please contact the Service Desk at
          {' '}
          <a href={'tel:916-464-4311'}>916-464-4311</a>
          {' '}
        and ask to have a work order routed to Internal Business Applications.
        </p>
        <hr />
        <h2 style={{ textAlign: 'center' }}>Guidelines and Regulations</h2>
        <h3 style={{ marginBottom: '0em' }}>Temporary Badge</h3>
        <p>
          Employees and visitors must adhere to the following temporary badge usage requirements in addition to the above
          ID badge regulations:
        </p>
        <ul>
          <li>Provides the same access as the original forgotten or lost ID badge</li>
          <li>May be taken off site, but must be returned to the reception desk each day upon leaving the facility</li>
        </ul>
        <p>
          The original forgotten or lost ID badge is temporarily deactivated until it is found and presented to
          the reception desk, at which time the temporary badge is returned and the original ID badge is reactivated.
        </p>
        <p style={{ fontStyle: 'italic' }}>
          <strong>Note: </strong>
          {' '}
          A second temporary badge shall not be issued if one is already assigned to the
          employee or visitor.
        </p>

        <h3 style={{ marginBottom: '0em' }}>ID Badge Access</h3>
        <p>
          Access to work areas is granted according to the locations indicated on the form pursuant to the
          following guidelines.
        </p>
        <ul>
          <li>
            <strong>Gold Camp</strong>
            {' '}
            campus employees and visitors are required to show their ID badge to the officer at the vehicle gate
            where, upon verification, access is granted.
          </li>
          <li>
            <strong>Croydon employees</strong>
            {' '}
            are required to use the badge readers to
            enter the building. Visitors can only be escorted by CDT employees with authorized access. Please refer to
            the Croydon Warehouse Visitor Procedure for details.
          </li>
          <li>
            <strong>Training Center</strong>
            {' '}
            employees are only required to use
            their badge during non-business hours and in the “employee only” areas within the building.
          </li>
        </ul>
        <h3 style={{ marginBottom: '0em' }}>Access Hours</h3>
        <p>
          ID badges grant building access during regular business hours (Monday-Friday, 6 am to 6 pm) through main
          entry and exit points. The use of alternate access points is prohibited.  Employees and visitors who do not
          normally have 24×7 access must follow these steps to gain access to the facility after hours:
        </p>
        <ul>
          <li>
            The supervisor must contact the Service Desk at (916) 464-4311 to inform them of the arriving
            individual and the arrival time.
          </li>
          <li>The Service Desk notifies the security guard personnel of the individual’s arrival.</li>
          <li>The security guard personnel follow internal procedures to grant the individual access to the facility.</li>
        </ul>
        <h3 style={{ marginBottom: '0em' }}>Specific Room Access</h3>
        <ul>
          <li>
            <strong>Computer Room</strong>
            {' '}
            access is limited to those whose regular job assignments substantiate a need to enter the computer room.
          </li>
          <strong>Telecommunications wiring closet</strong>
          {' '}
          access is limited to those employees whose regular job assignments substantiate a need to enter the closet.
        </ul>
        <h3 style={{ marginBottom: '0em' }}>Lost Photo ID Badges</h3>
        <p>Should an employee or visitor lose their ID badge, the following actions shall occur:</p>
        <ul>
          <li>Lost badges shall be reported to Security (916-228-6424) immediately.</li>
          <li>A high priority incident should be opened by contacting CDT’s Service Desk (916-464-4311).</li>
          <li>
              Show a valid picture ID (i.e., driver’s license) to the security officer at the Gold Camp vehicle entrance
              gate and/or the nearest security reception desk. The officers will verify the picture ID prior to
              granting access.
          </li>
          <li>Will be issued a temporary badge at the nearest security reception desk.</li>
          <li>
            The lost badge will be deactivated until the badge has been found and shown to security, or until an
            appointment has been made to provide a replacement photo badge.
          </li>
          <li>
              Replacement photo badges will only be issued after the badge has been lost for three business days.
          </li>
        </ul>

      </Page>

    </div>
  );
};

export default HelpPageContent;
