const badge = {
  badgeType: {
    name: 'badgeType',
    label: 'Badge Type',
    // isDisabled: true,
  },
};

const submitter = {
  name: {
    name: 'submitterName',
    label: 'Name',
    // disabled: true,
  },
  email: {
    name: 'submitterEmail',
    label: 'Email',
    // disabled: true,
  },
};

const approver = {
  name: {
    name: 'approverName',
    label: 'Name',
  },
  email: {
    name: 'approverEmail',
    label: 'Email',
  },
  phone: {
    name: 'approverPhone',
    label: 'Approver Phone',
    placeholder: '(Not Available)',
  },
};

const approval = {
  name: {
    label: 'Name',
  },
  date: {
    label: 'Date',
    placeholder: '(Not Available)',
  },
};

// const signature = {
//   name: {
//     required: true,
//     label: 'Name',
//   },
// };

const signature = {
  required: true,
  label: 'By clicking the "I Agree" and "Submit" buttons, you are signing this badge request electronically.',
  variant: 'primary',
  name: 'submitSignatureCheckbox',
  options: [
    { value: 'agreee', label: 'I agree' },
  ],
};

const securityChangeJustification = {
  required: true,
  name: 'securityChangeJustification',
  label: 'Security Change Justification',
  placeholder: '(None)',
};


const recipient = {
  name: {
    name: 'recipientName',
    label: 'Name',
    // disabled: true,
  },
  email: {
    name: 'recipientEmail',
    label: 'Email',
    // disabled: true,
  },
  phone: {
    name: 'recipientPhone',
    label: 'Phone',
    placeholder: '(Not Available)',
    // disabled: true,
  },
  cell: {
    name: 'recipientCell',
    label: 'Cell',
    placeholder: '(Not Available)',
    // disabled: true,
  },
  licensePlate: {
    name: 'recipientLicensePlate',
    label: 'License Plate',
    placeholder: '(Not Available)',
    // disabled: true,
  },
  company: {
    name: 'recipientCompany',
    label: 'Company',
    placeholder: '(Not Available)',
    // disabled: true,
  },
  campus: {
    name: 'recipientCampus',
    label: 'Campus',
    placeholder: '(Not Available)',
    // disabled: true,
  },
  division: {
    name: 'recipientDivision',
    label: 'Division',
    placeholder: '(Not Available)',
    // disabled: true,
  },
  unitProject: {
    name: 'recipientUnitProject',
    label: 'Unit / Project',
    placeholder: '(Not Available)',
    // disabled: true,
  },
};

const dates = {
  effective: {
    name: 'effectiveDate',
    label: 'Effective Date',
    placeholder: '(Not Available)',
    // disabled: true,
  },
  created: {
    name: 'createdDate',
    label: 'Created Date',
    placeholder: '(Not Available)',
    // disabled: true,
  },
  expiration: {
    name: 'expirationDate',
    label: 'Expiration Date',
    placeholder: '(Not Available)',
  },
};

const access = {
  reason: {
    required: true,
    name: 'reasonRadioButton',
    label: 'Reason',
  },
  areas: {
    required: true,
    name: 'areasCheckboxes',
    label: 'Areas',
  },
  hours: {
    required: true,
    name: 'hoursRadioButton',
    label: 'Hours',
  },
};

const cancelReject = {
  justification: {
    required: true,
    name: 'cancelRejectJustification',
    label: 'Reason',
    tag: 'textarea',
  },
  name: {
    required: true,
    name: 'cancelRejectName',
    label: 'Name',
  },
  date: {
    required: true,
    name: 'cancelRejectDate',
    label: 'Date',
  },

};

const schema = {
  badge, submitter, dates, approver, approval, signature, recipient, access, cancelReject, securityChangeJustification,
};


export default schema;
