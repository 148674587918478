import React from 'react';
import RefreshPendingCounts from '../../components/RefreshPendingCounts';
import NewRequestPageContent from './components/NewRequestPageContent';


const NewRequestPage = () => (
  <>
    <RefreshPendingCounts />
    <NewRequestPageContent
      refresh={Math.random()}
    />
  </>
);


export default NewRequestPage;
