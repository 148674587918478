import React from 'react';
import Request from '../../components/Request';
import RefreshPendingCounts from '../../components/RefreshPendingCounts';

const { PAGES, APPROVAL_TYPES, ROLES } = window.config;

export const MyRequestsPage = () => (
  <>
    <RefreshPendingCounts />
    <Request
      heading={'My Requests'}
      description={'This list contains all badge requests submitted by you.  By default, only the badge requests in a pending state are displayed.'}
      pageName={PAGES.MY_REQUESTS}
      approverTypeIds={[APPROVAL_TYPES.SUBMITTER]}
      role={ROLES.SUBMITTER}
      displaySearch
      refresh={Math.random()} // Forces page to rerender by updating prop
    />
  </>
);

export default MyRequestsPage;
