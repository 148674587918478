import React from 'react';
import Request from '../../components/Request';
import RefreshPendingCounts from '../../components/RefreshPendingCounts';

const { PAGES, APPROVAL_TYPES, ROLES } = window.config;

export const MyApprovalsPage = () => (
  <>
    <RefreshPendingCounts />
    <Request
      heading={'My Approval'}
      description={'This list contains the requests where you are the recipient.  By default, only the request awaiting your approval is shown.'}
      pageName={PAGES.MY_APPROVAL}
      approverTypeIds={[APPROVAL_TYPES.RECIPIENT]}
      role={ROLES.RECIPIENT}
      displaySearch
      refresh={Math.random()} // Forces page to rerender by updating prop
    />
  </>
);

export default MyApprovalsPage;
