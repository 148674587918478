import React, { useState } from 'react';
import { FieldInput, FieldCheckboxes, FieldRadioButtons } from 'state-template';
import { withRouter } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import T from 'prop-types';
import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import schema from '../schema';
// import { useMsalInstance } from '../../../utils/aureAuth2';

export const AccessRequirementsCard = (props) => {
  const {
    data,
    reasonTypes,
    hourTypes,
    areaTypes,
    role,
    displayFields,
  } = props;

  const { access } = schema;

  const [reasons, setReason] = useState(data.access.reason.id);
  const [areas, setAreas] = useState(data.access.areas.map((x) => x.value));
  const [hours, setHours] = useState(data.access.hours.id);

  const needReasonJustification = (event) => {
    setReason(event);
  };

  const needHoursJustification = (event) => {
    setHours(event);
  };

  const needAreasJustification = (event) => {
    setAreas(event);
  };

  const reasonOptions = reasonTypes.map((reasonType) => ({
    value: reasonType.id,
    label: reasonType.isJustificationRequired ? `${reasonType.description} *` : reasonType.description,
    justification: reasonType.isJustificationRequired,
    tooltip: reasonType.detail,
    placeholder: `Enter justification for ${reasonType.description}`,
  }));

  const hourOptions = hourTypes.map((hourType) => ({
    value: hourType.id,
    label: hourType.isJustificationRequired ? `${hourType.description} *` : hourType.description,
    justification: hourType.isJustificationRequired,
    tooltip: hourType.detail,
    placeholder: `Enter justification for ${hourType.description}`,
  }));

  const areaOptions = areaTypes.map((areaType) => ({
    value: areaType.id,
    label: areaType.isJustificationRequired ? `${areaType.description} *` : areaType.description,
    justification: areaType.isJustificationRequired,
    tooltip: areaType.detail,
    placeholder: `Enter justification for ${areaType.description}`,
  }));

  const renderReasonJustification = () => {
    const foundReason = reasonOptions.find((x) => x.value === reasons);

    if (foundReason && foundReason.justification === true) {
      return (
        <>
          <FieldInput
            required
            name={`reasons_${foundReason.value}`}
            label={foundReason.label.replace(' *', '')}
            tag={'textarea'}
            tooltip={foundReason.tooltip}
            placeholder={foundReason.placeholder}
            disabled={displayFields.accessRequirements[role].disabled}
            maxLength={500}
          />
          <div style={{ marginTop: '-15px', fontSize: '14px' }}>Must be 500 characters or less</div>
        </>
      );
    }

    return (
      null
    );
  };

  const renderHoursJustification = () => {
    const foundHour = hourOptions.find((x) => x.value === hours);

    if (foundHour && foundHour.justification === true) {
      return (
        <>
          <FieldInput
            required
            name={`hours_${foundHour.value}`}
            label={foundHour.label.replace(' *', '')}
            tag={'textarea'}
            tooltip={foundHour.tooltip}
            placeholder={foundHour.placeholder}
            disabled={displayFields.accessRequirements[role].disabled}
            maxLength={500}
          />
          <div style={{ marginTop: '-15px', fontSize: '14px' }}>Must be 500 characters or less</div>
        </>
      );
    }

    return (
      null
    );
  };

  const renderSingleAreaJustification = (area) => (
    <div key={`areas_${area.value}`}>
      <>
        <FieldInput
          required
          name={`areas_${area.value}`}
          label={area.label.replace(' *', '')}
          tag={'textarea'}
          tooltip={area.tooltip}
          placeholder={area.placeholder}
          disabled={displayFields.accessRequirements[role].disabled}
          maxLength={500}
        />
        <div style={{ marginTop: '-15px', fontSize: '14px' }}>Must be 500 characters or less</div>
      </>
    </div>
  );

  const renderAreasJustificationList = () => {
    const areaList = [];
    if (areas.length > 0 && areaOptions.length > 0) {
      for (let i = 0; i < areas.length; i += 1) {
        const foundArea = areaOptions.find((x) => x.value === areas[i]);
        if (foundArea && foundArea.justification) {
          areaList.push(renderSingleAreaJustification(foundArea));
        }
      }
    }

    return (
      areaList
    );
  };

  const renderAreasJustification = () => {
    if (areas) {
      return (
        <div>
          {
            renderAreasJustificationList()
          }
        </div>
      );
    }

    return (
      null
    );
  };

  return (
    <>
      <div className={'requestFormCard'}>
        <h3>Access Requirements</h3>
        <div className={'row'}>
          <div className={'col-md-4'}>
            <FieldRadioButtons
              {...access.reason}
              options={reasonOptions}
              onChange={needReasonJustification}
              disabled={displayFields.accessRequirements[role].disabled}
            />
          </div>
          <div className={'col-md-4'}>
            <FieldCheckboxes
              {...access.areas}
              options={areaOptions}
              onChange={needAreasJustification}
              disabled={displayFields.accessRequirements[role].disabled}
            />
          </div>
          <div className={'col-md-4'}>
            <div className={'col-md-4'} />
            <FieldRadioButtons
              {...access.hours}
              options={hourOptions}
              onChange={needHoursJustification}
              disabled={displayFields.accessRequirements[role].disabled}
            />
          </div>
        </div>
        {renderReasonJustification()}
        {renderAreasJustification()}
        {renderHoursJustification()}
      </div>
    </>
  );
};

AccessRequirementsCard.propTypes = {
  getHourTypes: T.func.isRequired,
  hourTypes: T.array,
  getAreaTypes: T.func.isRequired,
  areaTypes: T.array,
  getReasonTypes: T.func.isRequired,
  reasonTypes: T.array,
  data: T.object,
  disabled: T.bool,
  displayFields: T.object.isRequired,
  role: T.string.isRequired,
};

AccessRequirementsCard.defaultProps = {
  hourTypes: [],
  areaTypes: [],
  reasonTypes: [],
  data: {},
  disabled: true,
};


export const mapStateToProps = createStructuredSelector({
  hourTypes: selectors.getHourTypes(),
  areaTypes: selectors.getAreaTypes(),
  reasonTypes: selectors.getReasonTypes(),
});

export const mapDispatchToProps = (dispatch) => ({
  getHourTypes: (msalInstance) => dispatch(actions.getHourTypes(msalInstance)),
  getAreaTypes: (msalInstance) => dispatch(actions.getAreaTypes(msalInstance)),
  getReasonTypes: (msalInstance) => dispatch(actions.getReasonTypes(msalInstance)),
});


const usingRouter = withRouter(AccessRequirementsCard);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);

export default withRedux;
