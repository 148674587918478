import React, { useState } from 'react';
import { Navigation, MobileControls, Branding, SettingsBar, UtilityHeader, azureAuth2 } from 'state-template';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import T from 'prop-types';
import { useIsAuthenticated } from '@azure/msal-react';
import * as selectors from '../../redux/selectors';
import { HeaderRoutes } from '../../routes';
// import { SignInButton, SignOutButton } from '../../utils/aureAuth2';


const Header = (props) => {
  const {
    auth,
    pendingSecurityApprovals,
    pendingManagmentApprovals,
    pendingMyApprovals,
  } = props;

  const { MSAL } = window.config;

  const isAuthenticated = useIsAuthenticated();
  const title = '';
  const routes = HeaderRoutes(auth, pendingManagmentApprovals.totalCount, pendingMyApprovals.totalCount, pendingSecurityApprovals.totalCount);

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const toggleSettingsOpen = () => {
    if (isSettingsOpen === true) {
      setIsSettingsOpen(false);
    } else {
      setIsSettingsOpen(true);
    }
  };

  const toggleMobileOpen = () => {
    if (isMobileOpen === true) {
      setIsMobileOpen(false);
    } else {
      setIsMobileOpen(true);
    }
  };

  const brandingProps = {
    src: '/imgs/logo.png',
    alt: 'Badge Access Request System logo',
  };

  const contactLink = {
    text: '',
    href: 'https://cdt.ca.gov/support/',
  };

  const displayEnvironment = () => {
    const { ENVIRONMENT } = window.config;
    if (ENVIRONMENT) {
      if (ENVIRONMENT.toUpperCase() === 'LOCALHOST') {
        return (
          <div style={{ backgroundColor: 'goldenrod', textAlign: 'center', fontSize: 'xx-large', color: 'white' }}>{`${ENVIRONMENT.toUpperCase()}`}</div>
        );
      }
      if (ENVIRONMENT.toUpperCase() === 'DEVELOPMENT') {
        return (
          <div style={{ backgroundColor: 'blueviolet', textAlign: 'center', fontSize: 'xx-large', color: 'white' }}>{`${ENVIRONMENT.toUpperCase()}`}</div>
        );
      }
      if (ENVIRONMENT.toUpperCase() === 'TEST') {
        return (
          <div style={{ backgroundColor: 'darkgreen', textAlign: 'center', fontSize: 'xx-large', color: 'white' }}>{`${ENVIRONMENT.toUpperCase()}`}</div>
        );
      }
    }

    return null;
  };

  const settingsChildren = isAuthenticated
    ? (
      <div>
          Welcome,
        {' '}
        {auth ? auth.givenName : null}
        {' '}
        {auth ? auth.surname : null}
        {' '}
        <img className={'img-responsive'} style={{ marginLeft: '7px', marginBottom: '5px', borderRadius: '50%', height: '30px', width: '30px' }} src={auth.profilePicture} alt={'profile'} />
        <azureAuth2.SignOutButton />
      </div>
    )
    : (
      <azureAuth2.SignInButton
        msal={MSAL}
      />
    );

  return (
    <header
      style={{ position: 'fixed' }}
      className={'global-header right'}
    >
      {displayEnvironment()}
      <UtilityHeader
        settingsChildren={settingsChildren}
        toggleSettingsOpen={toggleSettingsOpen}
        contactLink={contactLink}
        title={title}
      />
      <SettingsBar
        toggleSettingsOpen={toggleSettingsOpen}
        isSettingsOpen={isSettingsOpen}
      />
      <Branding {...brandingProps} />
      <div className={'navigation-search'}>
        <MobileControls
          routes={routes}
          toggleMobileOpen={toggleMobileOpen}
        />
        <Navigation
          isMobileOpen={isMobileOpen}
          toggleMobileOpen={toggleMobileOpen}
          routes={routes}
        />
      </div>
    </header>
  );
};

Header.propTypes = {
  auth: T.object,
  pendingSecurityApprovals: T.oneOfType([
    T.string,
    T.object,
  ]),
  pendingManagmentApprovals: T.oneOfType([
    T.string,
    T.object,
  ]),
  pendingMyApprovals: T.oneOfType([
    T.string,
    T.object,
  ]),
};

Header.defaultProps = {
  auth: null,
  pendingSecurityApprovals: null,
  pendingManagmentApprovals: null,
  pendingMyApprovals: null,
};

export const mapStateToProps = createStructuredSelector({
  auth: selectors.getAuth(),
  pendingSecurityApprovals: selectors.getPendingSecurityApprovals(),
  pendingManagmentApprovals: selectors.getPendingManagementApprovals(),
  pendingMyApprovals: selectors.getPendingMyApprovals(),
});

// export const mapDispatchToProps = (dispatch) => ({
//   getPendingSecurityApprovals: (request) => dispatch(actions.getPendingSecurityApprovals(request)),
// });

export default connect(mapStateToProps, null)(Header);
