const recipientDetails = {
  badgeType: {
    required: true,
    name: 'badgeType',
    label: 'Badge Type',
    placeholder: 'Badge Type',
  },
  name: {
    required: true,
    name: 'name',
    label: 'Name',
    placeholder: 'Recipient',
  },
  email: {
    // required: true,
    disabled: true,
    name: 'email',
    label: 'Email',
    placeholder: 'Email',
  },
  phone: {
    // required: true,
    disabled: true,
    name: 'phone',
    label: 'Phone',
    placeholder: '(Not Available)',
  },
  cell: {
    disabled: true,
    name: 'cell',
    label: 'Cell',
    placeholder: '(Not Available)',
  },
  licensePlate: {
    required: true,
    name: 'licensePlate',
    label: 'License Plate',
    placeholder: 'License Plate',
    maxLength: 8,
  },
  campus: {
    // required: true,
    disabled: true,
    name: 'campus',
    label: 'Campus',
    placeholder: '(Not Available)',
  },
  division: {
    // required: true,
    disabled: true,
    name: 'division',
    label: 'Division',
    placeholder: '(Not Available)',
  },
  unitOrProject: {
    // required: true,
    disabled: true,
    name: 'unitOrProject',
    label: 'Unit / Project',
    placeholder: '(Not Available)',
  },
  effectiveDate: {
    required: true,
    name: 'effectiveDate',
    label: 'Effective Date',
    placeholder: 'Effective Date',
    tooltip: 'This will determine the effect date of access',
  },
};

const managerDetails = {
  approvingManager: {
    required: true,
    name: 'approvingManager',
    label: 'Approving Manager',
    placeholder: 'Approving Manager',
  },
};

const access = {
  reason: {
    required: true,
    name: 'reasonRadioButton',
    label: 'Reason',
  },
  areas: {
    required: true,
    name: 'areasCheckboxes',
    label: 'Areas',
  },
  hours: {
    required: true,
    name: 'hoursRadioButton',
    label: 'Hours',
  },
};

const schema = {
  recipientDetails,
  managerDetails,
  access,
};

export default schema;
