import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import T from 'prop-types';
import { FieldCheckboxes, FieldInput, Button, ButtonRow, azureAuth2, Form as ReduxForm } from 'state-template';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getFormValues, getFormInitialValues, isValid } from 'redux-form';
import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import schema from '../schema';
// import { useMsalInstance } from '../../../utils/aureAuth2';

export const ApproveRequestDialog = (props) => {
  const {
    formValuesRequest,
    formInitialValues,
    formValuesApproveRequestDialog,
    putApprove,
    isRequestValid,
    reasonTypes,
    hourTypes,
    areaTypes,
    currentRole,
    isDialogValid,
    callingApi,
  } = props;

  const msalInstance = azureAuth2.useMsalInstance();
  let formHasChanged = false;

  const [open, setOpen] = useState(false);

  const { signature, securityChangeJustification } = schema;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitApprove = () => {
    handleClose();
    callingApi();
    // const patch = [];

    const record = [];
    // record.id = formValuesRequest.id;

    // console.log('initial', formInitialValues);

    const foundReasonType = reasonTypes.find((type) => type.id === formValuesRequest.reasonRadioButton);

    if (formInitialValues.reasonRadioButton !== formValuesRequest.reasonRadioButton) {
      formHasChanged = true;
      record.push({
        path: 'reasonTypeId',
        op: 'replace',
        value: formValuesRequest.reasonRadioButton,
      });

      if (foundReasonType.isJustificationRequired) {
        record.push({
          path: 'reasonTypeJustification',
          op: 'replace',
          value: formValuesRequest[`reasons_${formValuesRequest.reasonRadioButton}`],
        });
      }
    } else if (formInitialValues[`reasons_${formValuesRequest.reasonRadioButton}`] !== formValuesRequest[`reasons_${formValuesRequest.reasonRadioButton}`]) {
      formHasChanged = true;
      record.push({
        path: 'reasonTypeJustification',
        op: 'replace',
        value: formValuesRequest[`reasons_${formValuesRequest.reasonRadioButton}`],
      });
    }

    let areasChanged = false;

    if (formValuesRequest.areasCheckboxes.length !== formInitialValues.areasCheckboxes.length) {
      areasChanged = true;
      formHasChanged = true;
    } else {
      // formValuesRequest.areasCheckboxes.forEach((checkbox) => {
      formValuesRequest.areasCheckboxes.forEach((checkbox) => {
        const found = formInitialValues.areasCheckboxes.find((x) => x === checkbox);
        if (!found) {
          areasChanged = true;
          formHasChanged = true;
          return;
        }

        const justification = areaTypes.find((type) => type.id === checkbox);
        if (justification) {
          if (formValuesRequest[`areas_${checkbox}`] !== formInitialValues[`areas_${checkbox}`]) {
            areasChanged = true;
            formHasChanged = true;
          }
        }
        // });
      });
    }

    if (areasChanged) {
      const areas = {
        op: 'replace',
        path: '/areas',
      };
      const updatedAreas = [];
      formValuesRequest.areasCheckboxes.forEach((checkbox) => {
        const justification = formValuesRequest[`areas_${checkbox}`];
        if (justification) {
          updatedAreas.push({
            areaTypeId: checkbox,
            justification,
          });
        } else {
          updatedAreas.push({
            areaTypeId: checkbox,
          });
        }
      });

      areas.value = updatedAreas;

      record.push(areas);
    }


    const foundHourType = hourTypes.find((type) => type.id === formValuesRequest.hoursRadioButton);

    // console.log(formInitialValues.hoursRadioButton, formValuesRequest.hoursRadioButton);

    if (formInitialValues.hoursRadioButton !== formValuesRequest.hoursRadioButton) {
      formHasChanged = true;
      record.push({
        path: 'hourTypeId',
        op: 'replace',
        value: formValuesRequest.hoursRadioButton,
      });
      if (foundHourType.isJustificationRequired) {
        record.push({
          path: 'hourTypeJustification',
          op: 'replace',
          value: formValuesRequest[`hours_${formValuesRequest.hoursRadioButton}`],
        });
      }
    } else if (formInitialValues[`hours_${formValuesRequest.hoursRadioButton}`] !== formValuesRequest[`hours_${formValuesRequest.hoursRadioButton}`]) {
      formHasChanged = true;
      record.push({
        path: 'hourTypeJustification',
        op: 'replace',
        value: formValuesRequest[`hours_${formValuesRequest.hoursRadioButton}`],
      });
    }

    if (currentRole === 'Security' && formHasChanged) {
      record.push({
        path: 'changeReason',
        op: 'replace',
        value: formValuesApproveRequestDialog.securityChangeJustification,
      });
    }

    // console.log('PATCH', record);
    const { id } = formValuesRequest;

    putApprove({ record, id, msalInstance });
  };

  const dialog = () => {
    if (formInitialValues.reasonRadioButton !== formValuesRequest.reasonRadioButton) {
      formHasChanged = true;
    } else
    if (formValuesRequest.areasCheckboxes.length !== formInitialValues.areasCheckboxes.length) {
      formHasChanged = true;
    } else
    if (formInitialValues.hoursRadioButton !== formValuesRequest.hoursRadioButton) {
      formHasChanged = true;
    } else
    if (formInitialValues[`reasons_${formValuesRequest.reasonRadioButton}`] !== formValuesRequest[`reasons_${formValuesRequest.reasonRadioButton}`]) {
      formHasChanged = true;
    } else
    if (formValuesRequest[`hours_${formValuesRequest.hoursRadioButton}`] !== formInitialValues[`hours_${formValuesRequest.hoursRadioButton}`]) {
      formHasChanged = true;
    } else {
      formValuesRequest.areasCheckboxes.forEach((checkbox) => {
        const found = formInitialValues.areasCheckboxes.find((x) => x === checkbox);
        if (!found) {
          formHasChanged = true;
          return;
        }

        const justification = areaTypes.find((type) => type.id === checkbox);
        if (justification) {
          if (formValuesRequest[`areas_${checkbox}`] !== formInitialValues[`areas_${checkbox}`]) {
            formHasChanged = true;
          }
        }
      });
    }

    if (currentRole === 'Security' && formHasChanged) {
      return (
        <DialogContent dividers>
          You have changed either the selected options and/or justification.  Please provide a reason for the change.
          <div>
            <>
              <FieldInput
                {...securityChangeJustification}
                tag={'textarea'}
                maxLength={500}
              />
              <div style={{ marginTop: '-15px', fontSize: '14px' }}>Must be 500 characters or less</div>
            </>
          </div>
          <div style={{ textAlign: 'center' }}>
            <FieldCheckboxes
              {...signature}
            />
          </div>
          <br />
          <ButtonRow style={{ textAlign: 'right' }}>
            <Button
              type={'submit'}
              className={'btn-custom-teal-outline'}
              name={'submit'}
              disabled={!isDialogValid}
            >
                  Submit
            </Button>
            <Button
              className={'btn-custom-teal-outline'}
              onClick={handleClose}
              name={'cancel'}
            >
                  Cancel
            </Button>
          </ButtonRow>
        </DialogContent>
      );
    }

    return (
      <DialogContent dividers>
        <div style={{ textAlign: 'center' }}>
          <FieldCheckboxes
            {...signature}
          />
        </div>
        <br />
        <ButtonRow style={{ textAlign: 'right' }}>
          <Button
            type={'submit'}
            className={'btn-custom-teal-outline'}
            name={'submit'}
            disabled={!isDialogValid}
          >
                Submit
          </Button>
          <Button
            className={'btn-custom-teal-outline'}
            onClick={handleClose}
            name={'cancel'}
          >
                Cancel
          </Button>
        </ButtonRow>
      </DialogContent>
    );
  };

  return (
    <>
      <Button
        id={'test'}
        className={'btn-custom-teal-outline'}
        iconProps={{ name: 'check-fill' }}
        onClick={handleOpen}
        disabled={!isRequestValid}
      >
        Approve
      </Button>

      <Dialog
        open={open}
        maxWidth={'lg'}
        style={{ zIndex: 3000 }}
        id={'approveRequestDialog'}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <ReduxForm
          form={'approveRequestDialog'}
          onSubmit={submitApprove}
        >
          <DialogTitle
            id={'scroll-dialog-title'}
          >
            <div style={{ textAlign: 'center' }}>
              Approve Request?
            </div>
          </DialogTitle>
          {dialog()}
          {/* <DialogContent dividers>
            <div style={{ textAlign: 'center' }}>
              <FieldCheckboxes
                {...signature}
              />
            </div>
            <br />
            <ButtonRow style={{ textAlign: 'right' }}>
              <Button
                type={'submit'}
                className={'btn-custom-teal-solid'}
                name={'submit'}
              >
                Submit
              </Button>
              <Button
                className={'btn-custom-teal-outline'}
                onClick={handleClose}
                name={'cancel'}
              >
                Cancel
              </Button>
            </ButtonRow>
          </DialogContent> */}
        </ReduxForm>
      </Dialog>
    </>
  );
};

ApproveRequestDialog.propTypes = {
  formValuesRequest: T.object,
  putApprove: T.func.isRequired,
  formInitialValues: T.object,
  formValuesApproveRequestDialog: T.object,
  isRequestValid: T.bool,
  isDialogValid: T.bool,
  currentRole: T.string.isRequired,
  reasonTypes: T.array,
  areaTypes: T.array,
  hourTypes: T.array,
  callingApi: T.func.isRequired,
};

ApproveRequestDialog.defaultProps = {
  formValuesRequest: {},
  formInitialValues: {},
  formValuesApproveRequestDialog: {},
  isRequestValid: false,
  isDialogValid: false,
  reasonTypes: [],
  areaTypes: [],
  hourTypes: [],
};

export const mapStateToProps = createStructuredSelector({
  formValuesRequest: getFormValues('request'),
  formInitialValues: getFormInitialValues('request'),
  formValuesApproveRequestDialog: getFormValues('approveRequestDialog'),
  isRequestValid: isValid('request'),
  isDialogValid: isValid('approveRequestDialog'),
  reasonTypes: selectors.getReasonTypes(),
  hourTypes: selectors.getHourTypes(),
  areaTypes: selectors.getAreaTypes(),
});

export const mapDispatchToProps = (dispatch) => ({
  putApprove: (request) => dispatch(actions.putApprove(request)),
});

const usingRouter = withRouter(ApproveRequestDialog);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);
export default withRedux;
