const { REQUEST_STATE } = window.config;

export const calculateSearchCheckboxes = (parameters) => {
  const checkboxes = [];

  if (parameters.pendingRecipientCheckbox && parameters.pendingRecipientCheckbox.length > 0) {
    checkboxes.push(REQUEST_STATE.PENDING_RECIPIENT_APPROVAL);
  }
  if (parameters.pendingManagerCheckbox && parameters.pendingManagerCheckbox.length > 0) {
    checkboxes.push(REQUEST_STATE.PENDING_MANAGER_APPROVAL);
  }
  if (parameters.pendingSupervisorCheckbox && parameters.pendingSupervisorCheckbox.length > 0) {
    checkboxes.push(REQUEST_STATE.PENDING_SUPERVISOR_APPROVAL);
  }
  if (parameters.pendingSecurityCheckbox && parameters.pendingSecurityCheckbox.length > 0) {
    checkboxes.push(REQUEST_STATE.PENDING_SECURITY_APPROVAL);
  }
  if (parameters.approvedCheckbox && parameters.approvedCheckbox.length > 0) {
    checkboxes.push(REQUEST_STATE.APPROVED);
  }
  if (parameters.cancelledRecipientCheckbox && parameters.cancelledRecipientCheckbox.length > 0) {
    checkboxes.push(REQUEST_STATE.CANCELLED_BY_RECIPIENT);
  }
  if (parameters.cancelledSubmitterCheckbox && parameters.cancelledSubmitterCheckbox.length > 0) {
    checkboxes.push(REQUEST_STATE.CANCELLED_BY_SUBMITTER);
  }
  if (parameters.cancelledManagerCheckbox && parameters.cancelledManagerCheckbox.length > 0) {
    checkboxes.push(REQUEST_STATE.CANCELLED_BY_MANAGER);
  }
  if (parameters.cancelledSupervisorCheckbox && parameters.cancelledSupervisorCheckbox.length > 0) {
    checkboxes.push(REQUEST_STATE.CANCELLED_BY_SUPERVISOR);
  }
  if (parameters.cancelledSecurityCheckbox && parameters.cancelledSecurityCheckbox.length > 0) {
    checkboxes.push(REQUEST_STATE.CANCELLED_BY_SECURITY);
  }
  if (parameters.cancelledDepartureCheckbox && parameters.cancelledDepartureCheckbox.length > 0) {
    checkboxes.push(REQUEST_STATE.CANCELLED_BY_DEPARTURE);
  }
  if (parameters.expiredCheckbox && parameters.expiredCheckbox.length > 0) {
    checkboxes.push(REQUEST_STATE.EXPIRED);
  }

  return checkboxes;
};

export const calcuateSearchFormValues = (parameters) => {
  const initialValues = {
    pendingRecipientCheckbox: parameters.pendingRecipientCheckbox !== undefined && parameters.pendingRecipientCheckbox !== null && parameters.pendingRecipientCheckbox.length > 0 ? [REQUEST_STATE.PENDING_RECIPIENT_APPROVAL] : [],
    pendingManagerCheckbox: parameters.pendingManagerCheckbox !== undefined && parameters.pendingManagerCheckbox !== null && parameters.pendingManagerCheckbox.length > 0 ? [REQUEST_STATE.PENDING_MANAGER_APPROVAL] : [],
    pendingSupervisorCheckbox: parameters.pendingSupervisorCheckbox !== undefined && parameters.pendingSupervisorCheckbox !== null && parameters.pendingSupervisorCheckbox.length > 0 ? [REQUEST_STATE.PENDING_SUPERVISOR_APPROVAL] : [],
    pendingSecurityCheckbox: parameters.pendingSecurityCheckbox !== undefined && parameters.pendingSecurityCheckbox !== null && parameters.pendingSecurityCheckbox.length > 0 ? [REQUEST_STATE.PENDING_SECURITY_APPROVAL] : [],
    approvedCheckbox: parameters.approvedCheckbox !== undefined && parameters.approvedCheckbox !== null && parameters.approvedCheckbox.length > 0 ? [REQUEST_STATE.APPROVED] : [],
    cancelledRecipientCheckbox: parameters.cancelledRecipientCheckbox !== undefined && parameters.cancelledRecipientCheckbox !== null && parameters.cancelledRecipientCheckbox.length > 0 ? [REQUEST_STATE.CANCELLED_BY_RECIPIENT] : [],
    cancelledSubmitterCheckbox: parameters.cancelledSubmitterCheckbox !== undefined && parameters.cancelledSubmitterCheckbox !== null && parameters.cancelledSubmitterCheckbox.length > 0 ? [REQUEST_STATE.CANCELLED_BY_SUBMITTER] : [],
    cancelledManagerCheckbox: parameters.cancelledManagerCheckbox !== undefined && parameters.cancelledManagerCheckbox !== null && parameters.cancelledManagerCheckbox.length > 0 ? [REQUEST_STATE.CANCELLED_BY_MANAGER] : [],
    cancelledSupervisorCheckbox: parameters.cancelledSupervisorCheckbox !== undefined && parameters.cancelledSupervisorCheckbox !== null && parameters.cancelledSupervisorCheckbox.length > 0 ? [REQUEST_STATE.CANCELLED_BY_SUPERVISOR] : [],
    cancelledSecurityCheckbox: parameters.cancelledSecurityCheckbox !== undefined && parameters.cancelledSecurityCheckbox !== null && parameters.cancelledSecurityCheckbox.length > 0 ? [REQUEST_STATE.CANCELLED_BY_SECURITY] : [],
    cancelledDepartureCheckbox: parameters.cancelledDepartureCheckbox !== undefined && parameters.cancelledDepartureCheckbox !== null && parameters.cancelledDepartureCheckbox.length > 0 ? [REQUEST_STATE.CANCELLED_BY_DEPARTURE] : [],
    expiredCheckbox: parameters.expiredCheckbox !== undefined && parameters.expiredCheckbox !== null && parameters.expiredCheckbox.length > 0 ? [REQUEST_STATE.EXPIRED] : [],
  };
  return initialValues;
};

export const disableSearchButton = (formValues) => {
  let isDisabled = true;
  if ((formValues.pendingRecipientCheckbox && formValues.pendingRecipientCheckbox.length > 0)
  || (formValues.pendingManagerCheckbox && formValues.pendingManagerCheckbox.length > 0)
  || (formValues.pendingSupervisorCheckbox && formValues.pendingSupervisorCheckbox.length > 0)
  || (formValues.pendingSecurityCheckbox && formValues.pendingSecurityCheckbox.length > 0)
  || (formValues.approvedCheckbox && formValues.approvedCheckbox.length > 0)
  || (formValues.cancelledRecipientCheckbox && formValues.cancelledRecipientCheckbox.length > 0)
  || (formValues.cancelledSubmitterCheckbox && formValues.cancelledSubmitterCheckbox.length > 0)
  || (formValues.cancelledManagerCheckbox && formValues.cancelledManagerCheckbox.length > 0)
  || (formValues.cancelledSupervisorCheckbox && formValues.cancelledSupervisorCheckbox.length > 0)
  || (formValues.cancelledSecurityCheckbox && formValues.cancelledSecurityCheckbox.length > 0)
  || (formValues.cancelledDepartureCheckbox && formValues.cancelledDepartureCheckbox.length > 0)
  || (formValues.expiredCheckbox && formValues.expiredCheckbox.length > 0)
  ) {
    isDisabled = false;
  } else {
    isDisabled = true;
  }

  return isDisabled;
};

export const caculateQueryParameters = (requests, statusTypeIds, history, pageParams, approverTypeIds, sortOrder, sortField) => {
  let queryParameters = null;

  if (approverTypeIds) {
    approverTypeIds.forEach((approverType) => {
      queryParameters = queryParameters !== null ? `${queryParameters}&ApproverTypeIds=${approverType}` : `ApproverTypeIds=${approverType}`;
    });
  }

  if (statusTypeIds) {
    statusTypeIds.forEach((statusTypeId) => {
      queryParameters = queryParameters !== null ? `${queryParameters}&StatusTypeIds=${statusTypeId}` : `StatusTypeIds=${statusTypeId}`;
    });
  }

  if (sortOrder) {
    queryParameters = queryParameters !== null ? `${queryParameters}&SortField=${sortField}&SortOrder=${sortOrder}` : `SortField=${sortField}&SortOrder=${sortOrder}`;
  }

  if (requests) {
    queryParameters += `&PageNumber=${pageParams.currentPage}&PageSize=${pageParams.pageSize}`;
  }

  return queryParameters;
};

export const selectAllSearchBoxes = (updateField, form) => {
  updateField(form, 'pendingRecipientCheckbox', [REQUEST_STATE.PENDING_RECIPIENT_APPROVAL]);
  updateField(form, 'pendingManagerCheckbox', [REQUEST_STATE.PENDING_MANAGER_APPROVAL]);
  updateField(form, 'pendingSupervisorCheckbox', [REQUEST_STATE.PENDING_SUPERVISOR_APPROVAL]);
  updateField(form, 'pendingSecurityCheckbox', [REQUEST_STATE.PENDING_SECURITY_APPROVAL]);
  updateField(form, 'approvedCheckbox', [REQUEST_STATE.APPROVED]);
  updateField(form, 'cancelledRecipientCheckbox', [REQUEST_STATE.CANCELLED_BY_RECIPIENT]);
  updateField(form, 'cancelledSubmitterCheckbox', [REQUEST_STATE.CANCELLED_BY_SUBMITTER]);
  updateField(form, 'cancelledManagerCheckbox', [REQUEST_STATE.CANCELLED_BY_MANAGER]);
  updateField(form, 'cancelledSupervisorCheckbox', [REQUEST_STATE.CANCELLED_BY_SUPERVISOR]);
  updateField(form, 'cancelledSecurityCheckbox', [REQUEST_STATE.CANCELLED_BY_SECURITY]);
  updateField(form, 'cancelledDepartureCheckbox', [REQUEST_STATE.CANCELLED_BY_DEPARTURE]);
  updateField(form, 'expiredCheckbox', [REQUEST_STATE.EXPIRED]);
};

export const unselectAllSearchBoxes = (updateField, form) => {
  updateField(form, 'pendingRecipientCheckbox', null);
  updateField(form, 'pendingManagerCheckbox', null);
  updateField(form, 'pendingSupervisorCheckbox', null);
  updateField(form, 'pendingSecurityCheckbox', null);
  updateField(form, 'approvedCheckbox', null);
  updateField(form, 'cancelledRecipientCheckbox', null);
  updateField(form, 'cancelledSubmitterCheckbox', null);
  updateField(form, 'cancelledManagerCheckbox', null);
  updateField(form, 'cancelledSupervisorCheckbox', null);
  updateField(form, 'cancelledSecurityCheckbox', null);
  updateField(form, 'cancelledDepartureCheckbox', null);
  updateField(form, 'expiredCheckbox', null);
};


const search = { selectAllSearchBoxes, unselectAllSearchBoxes, calculateSearchCheckboxes, calcuateSearchFormValues, caculateQueryParameters, disableSearchButton };
export default search;
