import React from 'react';
import RefreshPendingCounts from '../../components/RefreshPendingCounts';
import AuthHomePageContent from './components/AuthHomePageContent';


const HelpPage = () => (
  <>
    <RefreshPendingCounts />
    <AuthHomePageContent
      refresh={Math.random()}
    />
  </>
);


export default HelpPage;
