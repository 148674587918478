import React from 'react';
import { FieldInput } from 'state-template';
import * as T from 'prop-types';
import schema from '../schema';


export const ApprovalsDetailsCard = (props) => {
  const {
    data,
    displayFields,
    role,
  } = props;

  const { approval } = schema;

  const renderSingleApproval = (approver) => (
    <div key={`approval_${approver.type}_name`}>
      <h4 style={{ lineHeight: '0px', marginBottom: '.5em' }}>{approver.type}</h4>
      <div className={'row'}>
        <div className={'col-md-6'}>
          <FieldInput
            name={`approval_${approver.type}_name`}
            {...approval.name}
            disabled={displayFields.approvals[role].disabled}
          />
        </div>
        <div className={'col-md-6'}>
          <FieldInput
            name={`approval_${approver.type}_date`}
            {...approval.date}
            disabled={displayFields.approvals[role].disabled}
          />
        </div>
      </div>
    </div>
  );

  const renderApprovalsList = () => {
    const approvalsList = [];

    if (data.approvals && data.approvals.length > 0) {
      for (let i = 0; i < data.approvals.length; i += 1) {
        approvalsList.push(renderSingleApproval(data.approvals[i]));
      }
    }

    return (
      approvalsList
    );
  };

  const renderApprovals = () => {
    if (data) {
      return (
        <div>
          <div className={'requestFormCard'}>
            <h3>Approvals</h3>
            {renderApprovalsList() }
          </div>
        </div>
      );
    }

    return (
      null
    );
  };

  return (
    <>
      {renderApprovals()}
    </>
  );
};

ApprovalsDetailsCard.propTypes = {
  data: T.object,
  displayFields: T.object.isRequired,
  role: T.string.isRequired,
};

ApprovalsDetailsCard.defaultProps = {
  data: {},
};

export default ApprovalsDetailsCard;
