import React from 'react';
import * as T from 'prop-types';
import { Button } from 'state-template';

const PanelButton = ({
  text, variant, onClick, isOpen, ...rest
}) => (
  <Button
    text={text}
    onClick={onClick}
    iconProps={{ name: isOpen ? 'caret-up' : 'caret-down' }}
    className={'btn-custom-teal-outline'}
    {...rest}
  />
);

PanelButton.propTypes = {
  text: T.string.isRequired,
  variant: T.string,
  onClick: T.func.isRequired,
  isOpen: T.bool.isRequired,
};

PanelButton.defaultProps = {
  variant: 'default',
};

export default PanelButton;
