import React from 'react';
import { Button, ButtonRow, FieldCheckboxes } from 'state-template';
import T from 'prop-types';
import Panel from '../../Panel';

export const SearchPanel = (props) => {
  const {
    selectAllSearch,
    clearSearch,
    searchButtonDisabled,
  } = props;

  return (
    <>
      <div style={{ paddingBottom: '10px', textAlign: 'right' }}>
        <ButtonRow>
          <Button
            className={'btn-custom-teal-outline'}
            onClick={selectAllSearch}
          >
        Select All
          </Button>
          <Button
            className={'btn-custom-teal-outline'}
            onClick={clearSearch}
          >
        Clear All
          </Button>
        </ButtonRow>
      </div>
      <Panel>
        <div style={{ paddingBottom: '20px' }}>
          <div className={'searchBorder'}>
            <div className={'row'}>
              <div className={'col-md-3'}>
                <FieldCheckboxes
                  name={'pendingRecipientCheckbox'}
                  options={[
                    { value: 1, label: 'Pending Recipient' }]}
                />
              </div>
              <div className={'col-md-3'}>
                <FieldCheckboxes
                  name={'pendingManagerCheckbox'}
                  options={[
                    { value: 2, label: 'Pending Manager' }]}
                />
              </div>
              <div className={'col-md-3'}>
                <FieldCheckboxes
                  name={'pendingSupervisorCheckbox'}
                  options={[
                    { value: 3, label: 'Pending Supervisor' }]}
                />
              </div>
              <div className={'col-md-3'}>
                <FieldCheckboxes
                  name={'pendingSecurityCheckbox'}
                  options={[
                    { value: 4, label: 'Pending Security' }]}
                />
              </div>
            </div>
            <div className={'row'} style={{ marginTop: '-10px' }}>
              <div className={'col-md-3'}>
                <FieldCheckboxes
                  name={'approvedCheckbox'}
                  options={[
                    { value: 5, label: 'Approved' }]}
                />
              </div>
              <div className={'col-md-3'}>
                <FieldCheckboxes
                  name={'cancelledRecipientCheckbox'}
                  options={[
                    { value: 6, label: 'Cancelled By Recipient' }]}
                />
              </div>
              <div className={'col-md-3'}>
                <FieldCheckboxes
                  name={'cancelledSubmitterCheckbox'}
                  options={[
                    { value: 7, label: 'Cancelled By Submitter' }]}
                />
              </div>
              <div className={'col-md-3'}>
                <FieldCheckboxes
                  name={'cancelledManagerCheckbox'}
                  options={[
                    { value: 8, label: 'Cancelled By Manager' }]}
                />
              </div>
            </div>
            <div className={'row'} style={{ marginTop: '-10px' }}>
              <div className={'col-md-3'}>
                <FieldCheckboxes
                  name={'cancelledSupervisorCheckbox'}
                  options={[
                    { value: 9, label: 'Cancelled By Supervisor' }]}
                />
              </div>
              <div className={'col-md-3'}>
                <FieldCheckboxes
                  name={'cancelledSecurityCheckbox'}
                  options={[
                    { value: 10, label: 'Cancelled By Security' }]}
                />
              </div>

              <div className={'col-md-3'}>
                <FieldCheckboxes
                  name={'cancelledDepartureCheckbox'}
                  options={[
                    { value: 11, label: 'Cancelled By Departure' }]}
                />
              </div>

              <div className={'col-md-3'}>
                <FieldCheckboxes
                  name={'expiredCheckbox'}
                  options={[
                    { value: 12, label: 'Expired' }]}
                />
              </div>


            </div>
            <div style={{ paddingBottom: '10px', textAlign: 'right' }}>
              <Button
                text={'Search'}
                className={'btn-custom-teal-outline'}
                type={'submit'}
                disabled={searchButtonDisabled}
              />
            </div>
          </div>
        </div>
      </Panel>
    </>
  );
};

SearchPanel.propTypes = {
  selectAllSearch: T.func.isRequired,
  clearSearch: T.func.isRequired,
  searchButtonDisabled: T.bool,
};

SearchPanel.defaultProps = {
  searchButtonDisabled: true,
};

export default SearchPanel;
