import React from 'react';
import Request from '../../components/Request';
import RefreshPendingCounts from '../../components/RefreshPendingCounts';

const { PAGES, APPROVAL_TYPES, ROLES } = window.config;

export const ManagementPage = () => (
  <>
    <RefreshPendingCounts />
    <Request
      heading={'Manager'}
      description={'This list contains all badge requests you need to approve on behalf of your staff.  By default, only the badge requests in a pending state are displayed.'}
      pageName={PAGES.MANAGEMENT}
      approverTypeIds={[APPROVAL_TYPES.MANAGER, APPROVAL_TYPES.SUPERVISOR]}
      role={ROLES.MANAGEMENT}
      displaySearch
      refresh={Math.random()} // Forces page to rerender by updating prop
    />
  </>
);

export default ManagementPage;
