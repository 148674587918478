export const GET_EXAMPLE_DATA = 'Home/GET_EXAMPLE_DATA';
export const GET_EXAMPLE_DATA_SUCCESS = 'Home/GET_EXAMPLE_DATA_SUCCESS';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';

export const GET_AUTH = 'GET_AUTH';
export const GET_AUTH_SUCCESS = 'GET_AUTH_SUCCESS';

export const INIT_APP = 'INIT_APP';

export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';

export const GET_USER_PROFILE_PICTURE = 'GET_USER_PROFILE_PICTURE';
export const GET_USER_PROFILE_PICTURE_SUCCESS = 'GET_USER_PROFILE_PICTURE_SUCCESS';

export const GET_EMPLOYEES = 'GET_EMPLOYEES';
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYESS_SUCCESS';

export const GET_MANAGERS = 'GET_MANAGERS';
export const GET_MANAGERS_SUCCESS = 'GET_MANAGERS_SUCCESS';

export const GET_BADGE_TYPES = 'GET_BADGE_TYPES';
export const GET_BADGE_TYPES_SUCCESS = 'GET_BADGE_TYPES_SUCCESS';

export const GET_HOUR_TYPES = 'GET_HOUR_TYPES';
export const GET_HOUR_TYPES_SUCCESS = 'GET_HOUR_TYPES_SUCCESS';

export const GET_AREA_TYPES = 'GET_AREA_TYPES';
export const GET_AREA_TYPES_SUCCESS = 'GET_AREA_TYPES_SUCCESS';

export const GET_REASON_TYPES = 'GET_REASON_TYPES';
export const GET_REASON_TYPES_SUCCESS = 'GET_REASON_TYPES_SUCCESS';

export const GET_REQUESTS = 'GET_REQUESTS';
// export const GET_REQUESTS_SUCCESS = 'GET_REQUESTS_SUCCESS';

// export const GET_REQUESTSX = 'GET_REQUESTSX';
// export const GET_REQUESTSX_SUCCESS = 'GET_REQUESTSX_SUCCESS';

export const CLEAR_REQUESTS = 'CLEAR_REQUESTS';

export const GET_MY_REQUESTS = 'GET_MY_REQUESTS';
export const GET_MY_REQUESTS_SUCCESS = 'GET_MY_REQUESTS_SUCCESS';

export const GET_MY_APPROVALS = 'GET_MY_APPROVALS';
export const GET_MY_APPROVALS_SUCCESS = 'GET_MY_APPROVALS_SUCCESS';

export const POST_REQUEST = 'POST_REQUEST';
export const POST_REQUEST_SUCCESS = 'POST_REQUEST_SUCCESS';

export const PUT_APPROVE = 'PUT_APPROVE';
export const PUT_APPROVE_SUCCESS = 'PUT_APPROVE_SUCCESS';

export const PUT_CANCEL = 'PUT_CANCEL';
export const PUT_CANCEL_SUCCESS = 'PUT_CANCEL_SUCCESS';

export const PUT_ESCALATE = 'PUT_ESCALATE';
export const PUT_ESCALATE_SUCCESS = 'PUT_ESCALATE_SUCCESS';

// export const GET_APPROVER_REQUESTS = 'GET_APPROVER_REQUESTS';
// export const GET_APPROVER_REQUESTS_SUCCESS = 'IS_APPROVER_REQUESTS_SUCCESS';

export const GET_PENDING_SECURITY_APPROVALS = 'GET_PENDING_SECURITY_APPROVALS';
export const GET_PENDING_SECURITY_APPROVALS_SUCCESS = 'GET_PENDING_SECURITY_APPROVALS_SUCCESS';

export const GET_PENDING_MANAGEMENT_APPROVALS = 'GET_PENDING_MANAGEMENT_APPROVALS';
export const GET_PENDING_MANAGEMENT_APPROVALS_SUCCESS = 'GET_PENDING_MANAGEMENT_APPROVALS_SUCCESS';

export const GET_PENDING_MY_APPROVALS = 'GET_PENDING_MY_APPROVALS';
export const GET_PENDING_MY_APPROVALS_SUCCESS = 'GET_PENDING_MY_APPROVALS_SUCCESS';

export const GET_APPROVED_REQUESTS = 'GET_APPROVED_REQUESTS';
export const GET_APPROVED_REQUESTS_SUCCESS = 'GET_APPROVED_REQUESTS_SUCCESS';

export const GET_EMPLOYEE_MANAGER = 'GET_EMPLOYEE_MANAGER';
export const GET_EMPLOYEE_MANAGER_SUCCESS = 'GET_EMPLOYEE_MANAGER_SUCCESS';

export const GET_SECURITY_REQUESTS_SUCCESS = 'GET_SECURITY_REQUESTS_SUCCESS';
export const GET_SECURITY_SEARCH_PARAMETERS_SUCCESS = 'GET_SECURITY_SEARCH_PARAMETERS_SUCCESS';

export const GET_GUARD_REQUESTS_SUCCESS = 'GET_GUARD_REQUESTS_SUCCESS';
export const GET_GUARD_SEARCH_PARAMETERS_SUCCESS = 'GET_GUARD_SEARCH_PARAMETERS_SUCCESS';

export const GET_MANAGEMENT_REQUESTS_SUCCESS = 'GET_MANAGEMENT_REQUESTS_SUCCESS';
export const GET_MANAGEMENT_SEARCH_PARAMETERS_SUCCESS = 'GET_MANAGEMENT_SEARCH_PARAMETERS_SUCCESS';

export const GET_MY_REQUESTS_SEARCH_PARAMETERS_SUCCESS = 'GET_MY_REQUESTS_SEARCH_PARAMETERS_SUCCESS';

export const GET_MY_APPROVAL_SEARCH_PARAMETERS_SUCCESS = 'GET_MY_APPROVAL_SEARCH_PARAMETERS_SUCCESS';


export const GET_SEARCH_PARAMETERS = 'GET_SEARCH_PARAMETERS';
export const GET_SEARCH_PARAMETERS_SUCCESS = 'GET_SEARCH_PARAMETERS_SUCCESS';

export const GET_REFRESH_PENDING_COUNTS = 'GET_REFRESH_PENDING_COUNTS';
