import React from 'react';
import { FieldInput } from 'state-template';
import T from 'prop-types';
import schema from '../schema';

export const BadgeDetailsCard = (props) => {
  const {
    displayFields,
    data,
    role,
  } = props;
  const { badge, dates } = schema;

  return (
    <>
      <div className={'requestFormCard'}>
        <h3>Badge Details</h3>
        <div className={'row'}>
          <div className={'col-md-3'}>
            <FieldInput
              {...badge.badgeType}
              disabled={displayFields.badge[role].disabled}
            />
          </div>
          <div className={'col-md-3'}>
            <FieldInput
              {...dates.created}
              disabled={displayFields.badge[role].disabled}
            />
          </div>
          <div className={'col-md-3'}>
            <FieldInput
              {...dates.effective}
              disabled={displayFields.badge[role].disabled}
            />
          </div>
          <div className={'col-md-3'}>
            {data.expirationDate
            && (
            <FieldInput
              {...dates.expiration}
              disabled={displayFields.badge[role].disabled}
            />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

BadgeDetailsCard.propTypes = {
  data: T.object,
  displayFields: T.object,
  role: T.string.isRequired,
};

BadgeDetailsCard.defaultProps = {
  data: {},
  displayFields: {},
};

export default BadgeDetailsCard;
