import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import T from 'prop-types';
import { FieldCheckboxes, FieldReactSelect, azureAuth2, Button, ButtonRow, Form as ReduxForm } from 'state-template';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import * as actions from 'redux/actions';
import * as selectors from 'redux/selectors';
import { makeStyles } from '@material-ui/core/styles';
import schema from '../schema';
import newRequestSchema from '../../NewRequestPage/schema';

export const EscalateRequestDialog = (props) => {
  const {
    formValuesRequest,
    formValuesEscalate,
    putEscalate,
    managers,
    employeeManager,
    callingApi,
  } = props;

  const history = useHistory();

  const msalInstance = azureAuth2.useMsalInstance();

  const [open, setOpen] = useState(false);
  const [managerListState, setManagerListState] = useState([]);


  const { signature } = schema;
  const { managerDetails } = newRequestSchema;

  useEffect(() => {
    const managerList = [];
    if (managers && managers.length > 0) {
      managers.forEach((mgr) => {
        let entry = {};
        if (mgr.value === history.location.row.recipient.id) {
          entry = {
            value: mgr.value,
            label: `${mgr.label} - Recipient cannot be supervisor.`,
            isDisabled: true,
          };
        } else if (mgr.value === history.location.row.approver.id) {
          entry = {
            value: mgr.value,
            label: `${mgr.label} - Manager cannot be supervisor.`,
            isDisabled: true,
          };
        } else {
          entry = {
            value: mgr.value,
            label: mgr.label,
          };
        }
        managerList.push(entry);
      });
    }
    setManagerListState(managerList);
  }, [managers]);

  const useStyles = makeStyles({
    paperFullWidth: {
      overflowY: 'visible',
    },
    dialogContentRoot: {
      overflowY: 'visible',
    },
  });

  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitEscalation = () => {
    handleClose();
    callingApi();

    const record = {};
    record.id = formValuesRequest.id;

    if (employeeManager && employeeManager.status !== 404) {
      record.supervisorId = employeeManager.id;
    } else {
      record.supervisorId = formValuesEscalate.approvingManager.value;
    }

    putEscalate({ record, msalInstance });
  };

  const chooseEscalationManager = () => {
    if (employeeManager && employeeManager.status !== 404) {
      return null;
    }

    return (
      <>
        <br />
        The escalation manager cannot be identified.  Please choose another manager.
        <br />
        <FieldReactSelect
          {...managerDetails.approvingManager}
          options={managerListState}
          renderTarget={'default'}
          menuPlacement={'auto'}
        />
      </>
    );
  };

  return (
    <>
      <Button
        id={'escalate'}
        className={'btn-custom-teal-outline'}
        iconProps={{ name: 'arrow-fill-up' }}
        onClick={handleOpen}
      >
        Escalate
      </Button>

      <Dialog
        open={open}
        maxWidth={'md'}
        style={{ zIndex: 3000 }}
        fullWidth
        classes={{
          paperFullWidth: classes.paperFullWidth,
        }}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <ReduxForm
          form={'escalateDialog'}
          onSubmit={submitEscalation}
        >
          <DialogTitle
            id={'scroll-dialog-title'}
          >
            <div style={{ textAlign: 'center' }}>
              Escalate Request?
            </div>
          </DialogTitle>
          <DialogContent
            dividers
            classes={{
              root: classes.dialogContentRoot,
            }}
          >
            Are you sure you want to escalate this request?
            <br />
            {chooseEscalationManager()}
            <br />
            <div style={{ textAlign: 'center' }}>
              <FieldCheckboxes
                {...signature}
              />
            </div>
            <ButtonRow style={{ textAlign: 'right' }}>
              <Button
                type={'submit'}
                className={'btn-custom-teal-outline'}
                name={'submitReject'}
              >
                Submit
              </Button>
              <Button
                className={'btn-custom-teal-outline'}
                onClick={handleClose}
                name={'cancel'}
              >
                Cancel
              </Button>
            </ButtonRow>
          </DialogContent>
        </ReduxForm>
      </Dialog>
    </>
  );
};

EscalateRequestDialog.propTypes = {
  history: T.shape({
    push: T.func,
  }).isRequired,
  formValues: T.object,
  formValuesRequest: T.object,
  formValuesEscalate: T.object,
  putEscalate: T.func.isRequired,
  managers: T.array,
  employeeManager: T.object,
  callingApi: T.func.isRequired,
};

EscalateRequestDialog.defaultProps = {
  formValues: {},
  formValuesRequest: {},
  formValuesEscalate: {},
  managers: [],
  employeeManager: null,
};

export const mapStateToProps = createStructuredSelector({
  formValuesRequest: getFormValues('request'),
  formValuesEscalate: getFormValues('escalateDialog'),
  managers: selectors.getManagers(),
  employeeManager: selectors.getEmployeeManager(),
});

export const mapDispatchToProps = (dispatch) => ({
  putEscalate: (request) => dispatch(actions.putEscalate(request)),
});

const usingRouter = withRouter(EscalateRequestDialog);
const withRedux = connect(mapStateToProps, mapDispatchToProps)(usingRouter);
export default withRedux;
