const { ENVIRONMENT } = window.config;

export const headerPadding = () => {
  if (ENVIRONMENT) {
    return 'headerTopEnvPadding';
  }

  return 'headerTopPadding';
};

export const envHomeHeader = () => {
  if (ENVIRONMENT) {
    return 'headerTopHomePageEnvPadding';
  }

  return 'headerTopHomePagePadding';
};

const header = { headerPadding, envHomeHeader };

export default header;
