import React, { useState } from 'react';
import { Button } from 'state-template';
import T from 'prop-types';

export const DownloadPDFButton = (props) => {
  const {
    data,
    auth,
  } = props;

  const { BARS_API } = window.config;

  const [isLoading, setIsLoading] = useState(false);

  const showPdfIcon = () => {
    let prop = {};
    if (isLoading === false) {
      prop = {
        name: 'pdf',
      };
      return (
        prop
      );
    }

    return prop;
  };

  const downloadPDF = async () => {
    setIsLoading(true);
    const url = `${BARS_API}/request/${data.id}/download`;
    const headers = { Authorization: `Bearer ${auth.appAccessToken}` };

    const options = {
      headers,
    };

    await fetch(url, options).then((res) => res.blob())
      .then((blob) => {
        const url2 = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url2;
        link.setAttribute('download', `Request${data.id}-${data.recipient.name}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsLoading(false);
      });
  };

  return (
    <Button
      iconProps={showPdfIcon()}
      className={'btn-custom-teal-outline'}
      onClick={downloadPDF}
      disabled={isLoading}
    >
      {isLoading && (
      <i
        className={'fa fa-refresh fa-spin'}
        style={{ marginRight: '5px' }}
      />
      )}
      {isLoading && <span>Downloading ...</span>}
      {!isLoading && <span>Download PDF</span>}
    </Button>
  );
};

DownloadPDFButton.propTypes = {
  data: T.object.isRequired,
  auth: T.object.isRequired,
};

export default DownloadPDFButton;
