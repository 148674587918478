import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { configureStore, serviceWorker, azureAuth2 } from 'state-template';
import { BrowserRouter as Router } from 'react-router-dom';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import 'state-template/dist/style/core/css/cagov.core.min.css';
import 'state-template/dist/style/core/css/colorscheme-oceanside.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'style/style.scss';

import App from 'containers/App';
import reducer from 'redux/reducer';
import saga from 'redux/saga';

// import { msalConfig } from 'utils/aureAuth2';

const { MSAL } = window.config;

const msalInstance = new PublicClientApplication(azureAuth2.msalConfig(MSAL));

const reducers = {
  reducer,
};

const sagas = [
  saga,
];

const store = configureStore({ reducers, sagas });

ReactDOM.render(
  // <React.StrictMode>
  <Router>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </Provider>
  </Router>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

serviceWorker.register();
