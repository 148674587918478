import React from 'react';
import { FieldInput } from 'state-template';
import T from 'prop-types';
import schema from '../schema';

export const RecipientDetailsCard = (props) => {
  const {
    displayFields,
    role,
  } = props;
  const { recipient } = schema;

  return (
    <>
      <div className={'requestFormCard'}>
        <h3>Recipient Details</h3>
        <div className={'row'}>
          <div className={'col-md-4'}>
            <FieldInput
              {...recipient.name}
              disabled={displayFields.recipient[role].disabled}
            />
          </div>
          <div className={'col-md-4'}>
            <FieldInput
              {...recipient.email}
              disabled={displayFields.recipient[role].disabled}
            />
          </div>
          <div className={'col-md-4'}>
            <FieldInput
              {...recipient.phone}
              disabled={displayFields.recipient[role].disabled}
            />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-md-4'}>
            <FieldInput
              {...recipient.cell}
              disabled={displayFields.recipient[role].disabled}
            />
          </div>
          <div className={'col-md-4'}>
            <FieldInput
              {...recipient.licensePlate}
              disabled={displayFields.recipient[role].disabled}
            />
          </div>
          <div className={'col-md-4'}>
            <FieldInput
              {...recipient.company}
              disabled={displayFields.recipient[role].disabled}
            />
          </div>
        </div>
        <div className={'row'}>
          <div className={'col-md-4'}>
            <FieldInput
              {...recipient.campus}
              disabled={displayFields.recipient[role].disabled}
            />
          </div>
          {/* <div className={'col-md-4'}>
            <FieldInput
              {...recipient.division}
              disabled={displayFields.recipient[role].disabled}
            />
          </div> */}
          <div className={'col-md-4'}>
            <FieldInput
              {...recipient.unitProject}
              disabled={displayFields.recipient[role].disabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};

RecipientDetailsCard.propTypes = {
  displayFields: T.object.isRequired,
  role: T.string.isRequired,
};

export default RecipientDetailsCard;
