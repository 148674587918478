import React, { useEffect } from 'react';
import { Page } from 'state-template';
import { headerPadding } from '../../../utils/environment';

const AuthHomePageContent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={headerPadding()}>
      <Page>
        <h1 style={{ paddingTop: '10px', textAlign: 'center' }}>Badge Access Request System</h1>
        <p>Use the Badge Access Request System (BARS) to request a new photo ID badge or change access permissions.</p>
        <p style={{ fontSize: '20px' }}><strong>Requirements</strong></p>
        <ul>
          <li>Badge must be visible</li>
          <li>Badge must be worn on outermost garment</li>
          <li>Use the badge at all badge readers</li>
          <li>
            Do not allow anyone to enter simultaneously through badge accessible doors without badging – referred to as
            “piggybacking.” The only exception is the escorting of a visitor that has checked in with security and has
            an active visitor log.
          </li>
          <li>Do not allow other employees or visitors to use your&nbsp;badge</li>
          <li>
            Contact Physical Security by
            {' '}
            <a href={'mailto:CIOSecurityBadgingOffice@state.ca.gov?subject=Badge Access'}>email</a>
            {' '}
            or (916) 228-6424 if a&nbsp;badge is lost or found and&nbsp;a high priority ticket should be opened by
            contacting CDT’s Service Desk&nbsp;at (916) 464-4311.
          </li>
          <li>
            Expired badges require a re-certification of access via the Badge Access form for contractors and vendors.
            Employee’s access should be re-certified access via a
            {' '}
            <a href={'/new-request'}>new request</a>
            {' '}
              upon the expiration of credentials.
          </li>
        </ul>
      </Page>
      {' '}
    </div>
  );
};

export default AuthHomePageContent;
