
export const formatRequestRecords = (inputRecords, role, auth) => {
  const { REQUEST_STATE, APPROVAL_TYPES, ROLES } = window.config;

  let formattedRequestRecords = {};

  const calcRejectName = (x) => {
    let name = null;

    if (x.statusType.id === REQUEST_STATE.CANCELLED_BY_RECIPIENT) {
      const cancelAuthority = x.approvals.find((z) => z.approverTypeID === APPROVAL_TYPES.RECIPIENT);
      name = cancelAuthority.name;
    } else if (x.statusType.id === REQUEST_STATE.CANCELLED_BY_SUBMITTER) {
      const cancelAuthority = x.approvals.find((z) => z.approverTypeID === APPROVAL_TYPES.SUBMITTER);
      name = cancelAuthority.name;
    } else if (x.statusType.id === REQUEST_STATE.CANCELLED_BY_MANAGER) {
      const cancelAuthority = x.approvals.find((z) => z.approverTypeID === APPROVAL_TYPES.MANAGER);
      name = cancelAuthority.name;
    } else if (x.statusType.id === REQUEST_STATE.CANCELLED_BY_SUPERVISOR) {
      const cancelAuthority = x.approvals.find((z) => z.approverTypeID === APPROVAL_TYPES.SUPERVISOR);
      name = cancelAuthority.name;
    } else if (x.statusType.id === REQUEST_STATE.CANCELLED_BY_SECURITY) {
      const cancelAuthority = x.approvals.find((z) => z.approverTypeID === APPROVAL_TYPES.SECURITY);
      name = cancelAuthority.name;
    }

    return (
      name
    );
  };

  const effectiveRole = (x, currentRole) => {
    if (currentRole === ROLES.MANAGEMENT) {
      const manager = x.approvals.find((y) => y.approverTypeID === APPROVAL_TYPES.MANAGER);
      if (manager.id === auth.id) {
        return 'Manager';
      }

      return 'Supervisor';
    }

    return currentRole;
  };

  const securityName = (x) => {
    if (x.statusType.id === REQUEST_STATE.APPROVED && x.approvals && x.approvals.length > 0) {
      return x.approvals.find((approval) => approval.type === 'Security Approval');
    }

    return null;
  };

  if (inputRecords.items && inputRecords.items.length > 0) {
    formattedRequestRecords = inputRecords.items.map((x) => ({
      role: effectiveRole(x, role),
      // role,
      id: x.id,
      // status: {
      //   name: x.stateType.name,
      //   id: x.stateType.id,
      // },
      statusType: x.statusType,
      effectiveDate: x.effectiveDate,
      expirationDate: x.expirationDate ? x.expirationDate.split('T')[0] : null,
      createdDate: x.createdDate,

      // TODO: Verify GUID for securityApproval
      // security: x.security,
      // securityApproval: x.approvals ? x.approvals.find((y) => y.id === '0c1548a9-2a39-4b5c-998a-be4294e7ed39') : null,
      // badge: {
      //   label: x.badgeType.name,
      //   value: x.badgeType.id,
      // },
      isEscalated: x.isEscalated,
      badge: x.badgeType,
      submitter: x.submitter,
      approver: x.approver,
      recipient: x.recipient,
      securityChangeJustification: x.changeReason,

      access: {
        reason: {
          id: x.reasonType.id,
          justification: x.reasonType.justification,
        },
        hours: {
          id: x.hourType.id,
          justification: x.hourType.justification,
        },
        areas: x.accessAreas.map((area) => ({
          value: area.id,
          justification: area.justification,
        })),
        // hours: x.access.hours,
        // areas: x.access.areas.map((area) => ({
        //   value: area.id,
        //   justification: area.justification,
        // })),
      },
      approvals: x.approvals,
      cancelReject: {
        name: calcRejectName(x),
        justification: x.cancellationReason,
        date: x.cancellationDate,
      },
      security: {
        name: securityName(x) ? securityName(x).name : null,
      },
    }));
  }

  return formattedRequestRecords;
};

export default formatRequestRecords;
